import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom"
import { useApp } from "./AppProvider"
import { ROLES } from "./sections/sidebar/constants"
import MiniDrawer from "./sections/sidebar"
import Login from "./sections/login"
import RecoverPassword from "./sections/recoverPassword"
import ChangePassword from "./sections/changePassword"
import { ROUTES } from "./routers/constants"
import CreatePassword from "./sections/createPassword"
import NotificationSnackbar from "./components/notification-snackbar"
import RatesLanding from "./sections/landingExtension/RatesLanding"
import { SECTIONS } from "./sections/sidebar/constants"
import { getUserBusinessRates } from "./sections/ratesCalculator/services/api"

const Redirect1 = () => {
  const { currentUser } = useApp()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === ROLES.CLIENT || currentUser.role === ROLES.EXT) {
        navigate("/dashboard")
      } else {
        navigate("/tickets")
      }
    } else {
      navigate("/login")
    }
  }, [])
  return null
}

const Redirect2 = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/")
  }, [])
  return null
}

const AppRouter = () => {
  const {
    currentUser,
    shouldShowNotification,
    hideNotification,
    notificationMessage,
    notificationSeverity,
    notificationOrigin,
    notificationMargin,
  } = useApp()

  const [sections, setSections] = React.useState(
    SECTIONS.filter((section) => section.link != "rates-contact")
  )

  const fetchRates = React.useCallback(async (sec) => {
    try {
      const response = await getUserBusinessRates()
      const data = response.data
      if (data.length != 0) {
        const auxSections = SECTIONS.filter((section) =>
          section.link == "rates-contact"
        )
        sec.push(auxSections[0])
        setSections(sec)
      } else if (window.location.pathname == '/rates-contact') {
        window.location.href = '/home'
      }
    } catch (e) {
      console.log(e)
    }
  })

  useEffect(() => {
    if (currentUser) {
      const newSections = sections.map((section) => {
        if (section.roles.includes(currentUser.role)) {
          return section
        }
      }).filter(Boolean)
      if (currentUser.role === ROLES.CLIENT) {
        fetchRates(newSections)
      } else {
        setSections(newSections)
      }
    } else {
      setSections(SECTIONS.filter((section) => section.link != "rates-contact"))
    }
  }, [currentUser])

  return (
    <BrowserRouter>
      <NotificationSnackbar
        open={shouldShowNotification}
        setOpen={(open) => hideNotification()}
        message={notificationMessage}
        type={notificationSeverity}
        asOrigin={notificationOrigin}
        asMargin={notificationMargin}
      />
      <Routes>
        <Route exact path="/rates-landing" element={<RatesLanding />} />

        <Route exact path="/login" element={<Login />} />
        <Route path="/recover-password" element={<RecoverPassword />} exact />
        <Route path="/change-password" element={<ChangePassword />} exact />
        <Route path="/create-password" element={<CreatePassword />} exact />
        <Route element={<MiniDrawer user={currentUser} SECTIONS={sections} />}>
          {currentUser && ROUTES[currentUser.role]}
        </Route>
        <Route exact path="/" element={<Redirect1 />} />
        <Route path="*" element={<Redirect2 />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
