import LogoSpinmovil from "../../../../assets/esms-logo-blanco.svg"
import { makeStyles } from "@mui/styles"
import { Typography } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import EmailIcon from "@mui/icons-material/Email"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"

const useStyles = makeStyles(() => ({
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 120px 10px 90px",
    height: "370px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100%-240px)",
    boxShadow: " 5px 5px 15px rgba(0, 0, 0, 0.1)",
    fontFamily: "Open Sans, sans-serif",
    backgroundColor: "#1d1d1d",
    "@media (max-width: 1100px)": {
      padding: "10px 20px 10px 20px",
      height: "fit-content",
    },
    '@media (min-width: 1800px)': {
      padding: "10px 300px 10px 300px",
    }
  },
  footerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
    gap: "40px",
    padding: "1em 0",
    height: "fit-content",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  footerInfoRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "5px",
    width: "100%",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "5px",
    marginBottom: "0",
    padding: "0",
  },
  connectium: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "20px",
    marginBottom: "0",
    padding: "0 0 0 23px",
    width: "calc(100% - 23px)",
    marginTop: "20px",
    '@media (max-width: 800px)': {
      gap: "40px",
      padding: "0 0 0 10px",
    }
  },
  socialMedia: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "16px",
    marginBottom: "0",
    marginTop: "25px",
    padding: "0",
    width: "100%",
  },
  footerCopyright: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
    height: "fit-content",
  },
  connectiumLogo: {
    width: "175px",
    height: "auto",
    "@media (max-width: 800px)": {
      width: "100px",
    },
  },
  spinmovilLogo: {
    width: "350px",
    height: "auto",
    marginBottom: "20px",
    "@media (max-width: 800px)": {
      width: "250px",
      marginBottom: "90px",
    },
  },
}))

const redirectSpinMovil = (path) => {
  window.location.href = process.env.REACT_APP_SPINMOVIL_LINK + path
}

const redirectContact = () => {
  window.location.href = process.env.REACT_APP_CONTACT_LINK
}

const redirectMail = () => {
  window.location.href = "mailto:ventas@spinmovil.com"
}

const redirectLinkdn = () => {
  window.location.href = "https://www.linkedin.com/showcase/spinm%C3%B3vil/"
}

const redirectInstagram = () => {
  window.location.href =
    "https://www.instagram.com/spinmovil/?igsh=MXFjdWt6M21hYXhxZw%3D%3D#"
}

const RatesLandingFooter = () => {
  const classes = useStyles()
  return (
    <div className={classes.footerContainer} id="footer">
      <div className={classes.footerInfo}>
        <div className={classes.footerInfoRow}>
          <img
            src="https://spinmovil.com/wp-content/uploads/2024/05/Logo-SpinMovil-300x93.png"
            alt=""
            className={classes.spinmovilLogo}
          />
          <div className={classes.info}>
            <LocationOnIcon sx={{ color: "white", width: "17px" }} />
            <Typography sx={{ color: "white", fontSize: "14px" }}>
              Edificio Caracas Campus (antes P&G), Piso 1, Calle Altagracia,
              Baruta 1080, Caracas. Edo. Miranda, Venezuela.
            </Typography>
          </div>
          <div className={classes.info}>
            <EmailIcon sx={{ color: "white", width: "17px" }} />
            <Typography
              sx={{ color: "white", fontSize: "14px", cursor: "pointer" }}
              onClick={redirectMail}
            >
              ventas@spinmovil.com
            </Typography>
          </div>
          <div className={classes.connectium}>
            <Typography
              sx={{ color: "white", fontSize: "14px", cursor: "pointer" }}
              onClick={redirectMail}
            >
              Una marca de:
            </Typography>
            <img
              src="https://spinmovil.com/wp-content/uploads/2024/01/conectium-blanco-1024x293.png"
              alt=""
              className={classes.connectiumLogo}
            />
          </div>
        </div>
        <div className={classes.footerInfoRow}>
          <Typography
            sx={{
              color: "white",
              fontSize: "25px",
              fontWeight: "700",
              margin: "20px 0 20px 0",
            }}
          >
            Nosotros
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: "14px", lineHeight: "25px" }}
          >
            <strong>SpinMóvil</strong> es una división de{" "}
            <span>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://www.conectium.com/"
              >
                <strong>Conectium Limited Venezuela, C.A.</strong>
              </a>
            </span>
            <br />
            La plataforma <strong>CleverTap</strong>&nbsp;es un Multichannel
            Marketing Hub que permite a las empresas gestionar sus campañas de
            marketing a través de múltiples canales.
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "25px",
              marginTop: "10px",
            }}
          >
            <strong>SpinMóvil</strong> es un Channel Partner de{" "}
            <strong>CleverTap</strong> para Venezuela{" "}
            <img
              draggable="false"
              role="img"
              width={15}
              alt="🇻🇪"
              src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1fb-1f1ea.svg"
            />
            .
          </Typography>
          <div className={classes.socialMedia}>
            <LinkedInIcon
              sx={{ color: "white", fontSize: "45px", cursor: "pointer" }}
              onClick={redirectLinkdn}
            />
            <InstagramIcon
              sx={{ color: "white", fontSize: "40px", cursor: "pointer" }}
              onClick={redirectInstagram}
            />
          </div>
        </div>
      </div>
      <div className={classes.footerCopyright}>
        <Typography
          sx={{ color: "white", fontSize: "13px", marginBottom: "10px" }}
        >
          Copyright 2024©️ SpinMóvil. Todos los derechos reservados.
        </Typography>
      </div>
    </div>
  )
}

export default RatesLandingFooter
