import { makeStyles } from "@mui/styles"
import * as React from "react"
import { CardContent, Typography, Card } from "@mui/material"
import downloadIcon from "../../../../assets/download-icon.svg"
import LogoSpinmovil from "../../../../assets/Logo-Spinmovil.png"
import html2canvas from "html2canvas"
import jsPdf from "jspdf"

const useStyles = makeStyles(() => ({
  divContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "calc(100% -160px)",
    justifyContent: "center",
    textAlign: "center",
    padding: "0 80px",
    "@media (max-width: 600px)": {
      padding: "0 20px",
      width: "calc(100% - 40px)",
    },
  },
  titleText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "black",
    margin: "0 0 0 0",
    padding: "0",
    textAlign: "center",
  },
  inputText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "black",
    margin: "0",
    padding: "0.3em 0",
  },
  subtitleText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "600",
    color: "black",
    margin: "0",
    padding: "0.3em 0",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0",
    padding: "0 20px",
    width: "calc(100% - 40px)",
    "@media (max-width: 600px)": {
      padding: "0",
      width: "calc(100%)",
    },
  },
  planInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    width: "100%",
    padding: "0 ",
    margin: "30px 0 20px 0",
  },
  field: {
    backgroundColor: "white",
    border: "2px solid black",
    borderRadius: "0.5em",
    padding: "0.8em",
  },
  errorField: {
    border: "2px solid #b30d09",
    backgroundColor: "white",
    borderRadius: "0.5em",
    padding: "0.8em",
  },
  PriceText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "black",
    margin: "0",
    padding: "0em 0",
    textAlign: "center",
  },
  hr: {
    width: "100%",
    height: "1px",
    margin: "10px 0",
    backgroundImage:
      "linear-gradient(to right, #333 60%, rgba(255, 255, 255, 0) 20%)",
    backgroundPosition: "top",
    backgroundSize: "16px 1px",
    backgroundRepeat: "repeat-x",
  },
  resultContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 40px)",
    marginBottom: "25px",
    padding: "0 20px",
    "@media (max-width: 600px)": {
      padding: "0",
      width: "calc(100%)",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "calc(100%)",
    justifyContent: "space-between",
    margin: "0 0 13px 0",
    padding: "0",
    "@media (max-width: 600px)": {
      margin: "0 0 13px 0",
    },
  },
  contactButton: {
    backgroundColor: "#1bc3dd",
    color: "white",
    fontWeight: "700 !important",
    fontSize: "20px",
    height: "60px",
    width: "168px",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    margin: "0 0 0 25px",
    "@media (max-width: 600px)": {
      margin: "0",
    },
  },
  downloadButton: {
    backgroundColor: "#1bc3dd",
    color: "white",
    height: "56px",
    width: "56px",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "0 25px 0 0",
    "@media (max-width: 600px)": {
      margin: "0",
    },
  },
  logoContainer: {
    display: "none",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    marginBottom: "15px",
    padding: "0",
  },
  LogoSpinmovil: {
    height: "64px",
    width: "202px",
    padding: "0em 0",
    margin: "0em 0",
  },
}))

const CalculatorOutput = (calculatorData) => {
  const classes = useStyles()

  const redirectContact = () => {
    window.location.href = process.env.REACT_APP_CONTACT_LINK
  }

  const downloadPDF = () => {
    window.innerWidth = 1440
    window.innerHeight = 840
    const domElement = document.getElementById("card-keep")
    html2canvas(domElement, {
      onclone: (document) => {
        document.getElementsByClassName("titleCard")[0].innerHTML =
          "Calculadora de campañas SMS"
        document.getElementById("subtitleContainer").style.display = "flex"
        document.getElementById("planInfoContainer").style.display = "flex"
        document.getElementById("logoContainer").style.display = "flex"
        document.getElementById("buttonsContainer").style.display = "none"
      },
    }).then((canvas) => {
      const img = canvas.toDataURL("image/png")
      const pdf = new jsPdf()
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = "0" + dd
      if (mm < 10) mm = "0" + mm
      pdf.addImage(img, "JPG", 20, 20, 100, 160)
      pdf.save("Cálculo_Campaña_" + dd + mm + yyyy + ".pdf")
    })
  }

  return (
    <div
      style={{
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "403px",
        minWidth: "300px",
        height: "fit-content",
        gap: "1em",
        backgroundColor: "white",
        border: "1px solid black",
      }}
      id="card-keep"
    >
      <CardContent
        sx={{
          width: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "25px 30px 0 30px",
        }}
      >
        <div className={classes.logoContainer} id="logoContainer">
          <img
            src={LogoSpinmovil}
            alt="SpinMovil Logo"
            className={classes.LogoSpinmovil}
          />
        </div>
        <div className={classes.titleContainer}>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "25px !important",
              lineHeight: "30px !important",
              letterSpacing: "0.15px !important",
              color: "black !important",
              marginTop: "0.2em",
              marginBottom: "22px",
              fontFeatureSettings: "'clig' off, 'liga' off",
              "@media (max-width: 450px)": {
                fontSize: "20px !important",
              },
            }}
            className="titleCard"
          >
            Total a Pagar
          </Typography>
        </div>
        <div
          className={classes.titleContainer}
          id="subtitleContainer"
          style={{ display: "none" }}
        >
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "20px !important",
              lineHeight: "30px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "0.2em",
              marginBottom: "30px",
              fontFeatureSettings: "'clig' off, 'liga' off",
              "@media (max-width: 450px)": {
                fontSize: "20px !important",
              },
            }}
          >
            Total a pagar
          </Typography>
        </div>
        <div
          className={classes.dataContainer}
          id="planInfoContainer"
          style={{ display: "none" }}
        >
          <div className={classes.planInfoContainer}>
            <Typography
              sx={{
                fontfamily: "Open Sans !important",
                fontStyle: "normal !important",
                fontWeight: "400 !important",
                fontSize: "16px !important",
                lineHeight: "24px !important",
                letterSpacing: "0.15px !important",
                color: "#363636 !important",
                margin: "0",
              }}
            >
              {calculatorData.calculatorData.plan == "planBasico"
                ? "Plan Básico"
                : "Plan Corporativo"}
            </Typography>
            <Typography
              sx={{
                fontfamily: "Open Sans !important",
                fontStyle: "normal !important",
                fontWeight: "700 !important",
                fontSize: "14px !important",
                lineHeight: "24px !important",
                letterSpacing: "0.15px !important",
                color: "#363636 !important",
                margin: "0",
              }}
            >
              {calculatorData.calculatorData.plan == "planBasico"
                ? "Desde 1.000 hasta 50.000 SMS"
                : "A partir de 50.001 SMS"}
            </Typography>
          </div>
        </div>
        <div className={classes.dataContainer}>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "400 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              margin: "0",
              padding: "0.5em 0",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            Cantidad de SMS:
          </Typography>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              margin: "0",
              padding: "0.5em 0",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            {calculatorData.calculatorData.msgQuantity.toLocaleString("de-DE")}
          </Typography>
        </div>
        <div className={classes.hr} />
        <div className={classes.dataContainer}>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "400 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              margin: "0",
              padding: "0.5em 0",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            Precio por SMS:
          </Typography>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              margin: "0",
              padding: "0.5em 0",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            {calculatorData.calculatorData.pricePerMessage.toLocaleString(
              "de-DE",
              { minimumFractionDigits: 3, maximumFractionDigits: 3 }
            )}
          </Typography>
        </div>
        <div className={classes.hr} />

        <div className={classes.resultContainer}>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "20px",
              marginBottom: "0.5em",
              fontFeatureSettings: "'clig' off, 'liga' off",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            Precio total a pagar:
          </Typography>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "20px",
              marginBottom: "0.5em",
              fontFeatureSettings: "'clig' off, 'liga' off",
              textAlign: "right",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
              },
            }}
          >
            {(
              calculatorData.calculatorData.msgQuantity *
              calculatorData.calculatorData.pricePerMessage
            ).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            USD
          </Typography>
        </div>
        <Typography
          sx={{
            fontfamily: "Open Sans !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "12px !important",
            lineHeight: "16px !important",
            letterSpacing: "0.15px !important",
            color: "#363636 !important",
            marginTop: "0.2em",
            marginBottom: "2px",
            fontFeatureSettings: "'clig' off, 'liga' off",
            "@media (max-width: 600px)": {
              fontSize: "10px !important",
            },
          }}
          className="legalText"
        >
          - Las tarifas mostradas en esta calculadora son referenciales, están
          sujetas a cambios y no incluyen el Impuesto al Valor Agregado (IVA).
        </Typography>
        <Typography
          sx={{
            fontfamily: "Open Sans !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "12px !important",
            lineHeight: "16px !important",
            letterSpacing: "0.15px !important",
            width: "100%",
            color: "#363636 !important",
            marginTop: "0.2em",
            marginBottom: "36px",
            fontFeatureSettings: "'clig' off, 'liga' off",
            "@media (max-width: 600px)": {
              fontSize: "10px !important",
            },
          }}
          className="legalText"
        >
          - Pagaderos en bolívares a la tasa referencial del BCV.
        </Typography>
        <div className={classes.buttonsContainer} id="buttonsContainer">
          <button
            className={classes.contactButton}
            onClick={() => redirectContact()}
          >
            Contáctanos
          </button>
          <button
            className={classes.downloadButton}
            onClick={() => downloadPDF()}
          >
            <img src={downloadIcon} alt="|" width="16px" />
          </button>
        </div>
      </CardContent>
    </div>
  )
}

export default CalculatorOutput
