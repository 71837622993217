import { makeStyles } from "@mui/styles"
import * as React from "react"
import { CardContent, Typography, Card } from "@mui/material"
import downloadIcon from "../../../../assets/download-icon.svg"
import LogoSpinmovil from "../../../../assets/Logo-Spinmovil.png"

import PropTypes from "prop-types"

import html2canvas from "html2canvas"
import jsPdf from "jspdf"

const useStyles = makeStyles(() => ({
  divContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "34px",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "37px",
  },
  titleText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "black",
    margin: "0 0 0 0",
    padding: "0",
    textAlign: "center",
  },

  dataContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    padding: "0",
    width: "100%",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "39px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    backgroundColor: "#4E5589",
    color: "white",
    marginBottom: "19px",
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    gap: "12px",
    marginBottom: "13px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "24px",
    paddingLeft: "36px",
  },
  resultRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "24px",
    paddingLeft: "36px",
    marginBottom: "62px",
  },
  hr: {
    width: "542px",
    height: "1px",
    margin: "0 0 12px 0",
    backgroundImage:
      "linear-gradient(to right, #333 60%, rgba(255, 255, 255, 0) 20%)",
    backgroundPosition: "top",
    backgroundSize: "16px 1px",
    backgroundRepeat: "repeat-x",
  },
  legalContainer: {
    width: "600px",
    padding: "0 67px 0 35px",
  },
  downloadButton: {
    backgroundColor: "#1bc3dd",
    color: "white",
    height: "56px",
    width: "56px",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "0 25px 0 0",
    position: "relative",
    left: "550px",
    top: "-40px",
  },
  logoContainer: {
    display: "none",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    marginBottom: "80px",
    padding: "0",
  },
  LogoSpinmovil: {
    height: "64px",
    width: "222px",
    padding: "0em 0",
    margin: "0em 0",
  },
}))

const CalculatorOutput = ({ calculatorData, sendMinimumBillingError }) => {
  const classes = useStyles()

  const [minimumBillingError, setMinimumBillingError] = React.useState(false)
  const [disabledDownload, setDisabledDownload] = React.useState(false)

  const downloadPDF = () => {
    if (minimumBillingError) return
    window.innerWidth = 1440
    window.innerHeight = 840
    const domElement = document.getElementById("card-keep")
    html2canvas(domElement, {
      onclone: (document) => {
        document.getElementById("logoContainer").style.display = "flex"
      },
    }).then((canvas) => {
      const img = canvas.toDataURL("image/png")
      const pdf = new jsPdf()
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1
      let dd = today.getDate()

      if (dd < 10) dd = "0" + dd
      if (mm < 10) mm = "0" + mm
      pdf.addImage(img, "JPG", 20, 20, 160, 140)
      pdf.save("Cálculo_Campaña_" + dd + mm + yyyy + ".pdf")
    })
  }

  React.useEffect(() => {
    if (
      parseInt(calculatorData.msgQuantity.Movilnet.replaceAll(".", "")) *
        calculatorData.prices.Movilnet +
        parseInt(calculatorData.msgQuantity.Movistar.replaceAll(".", "")) *
          calculatorData.prices.Movistar +
        parseInt(calculatorData.msgQuantity.Digitel.replaceAll(".", "")) *
          calculatorData.prices.Digitel <
        calculatorData.prices.MinimumBilling &&
      calculatorData.prices.MinimumBilling > 0
    ) {
      setMinimumBillingError(true)
      sendMinimumBillingError(true)
      setDisabledDownload(true)
    } else {
      setMinimumBillingError(false)
      sendMinimumBillingError(false)
      setDisabledDownload(false)
    }
    if (
      parseInt(calculatorData.msgQuantity.Movilnet.replaceAll(".", "")) *
        calculatorData.prices.Movilnet +
        parseInt(calculatorData.msgQuantity.Movistar.replaceAll(".", "")) *
          calculatorData.prices.Movistar +
        parseInt(calculatorData.msgQuantity.Digitel.replaceAll(".", "")) *
          calculatorData.prices.Digitel <=
      0
    ) {
      if (calculatorData.prices.MinimumBilling <= 0) {
        setDisabledDownload(true)
      }
    } else {
      if (calculatorData.prices.MinimumBilling <= 0) {
        setDisabledDownload(false)
      }
    }
  }, [calculatorData])

  return (
    <div style={{ display: "flex", flexDirection: "column" }} id="card-keep">
      <div className={classes.logoContainer} id="logoContainer">
        <img
          src={LogoSpinmovil}
          alt="SpinMovil Logo"
          className={classes.LogoSpinmovil}
        />
      </div>
      <div
        style={{
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "675px",
          height: "476px",
          backgroundColor: "white",
          border: "1px solid black",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 8px 38px 8px",
          }}
        >
          <div className={classes.titleContainer}>
            <Typography
              sx={{
                fontfamily: "Open Sans !important",
                fontStyle: "normal !important",
                fontWeight: "700 !important",
                fontSize: "25px !important",
                lineHeight: "normal !important",
                letterSpacing: "0.15px !important",
                color: "black !important",
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}
              className="titleCard"
            >
              Cotización
            </Typography>
          </div>
          <div className={classes.dataContainer} id="planInfoContainer">
            <div className={classes.tableHeader}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "88px",
                  alignContent: "center",
                  justifyContent: "start",
                  margin: "0 67px 0 36px",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "white !important",
                  }}
                  className="titleCard"
                >
                  Operadora
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "86px",
                  alignContent: "center",
                  justifyContent: "start",
                  marginRight: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "white !important",
                  }}
                  className="titleCard"
                >
                  Tarifa USD
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "135px",
                  alignContent: "center",
                  justifyContent: "start",
                  marginRight: "52px",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "white !important",
                  }}
                  className="titleCard"
                >
                  Cantidad de SMS
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "79px",
                  alignContent: "center",
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "white !important",
                  }}
                  className="titleCard"
                >
                  Total USD
                </Typography>
              </div>
            </div>

            {
              //Body of the table
            }

            <div className={classes.tableBody}>
              <div className={classes.tableRow}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "start",
                    marginRight: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "15px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    Digitel 412/422
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "86px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.prices.Digitel.toLocaleString("de-DE", {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "11px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.msgQuantity.Digitel}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "120px",
                    alignContent: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    {(
                      parseInt(
                        calculatorData.msgQuantity.Digitel.replaceAll(".", "")
                      ) * calculatorData.prices.Digitel
                    ).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " USD"}
                  </Typography>
                </div>
              </div>

              <div className={classes.tableRow}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "start",
                    marginRight: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "15px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    Movistar 414/424
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "86px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.prices.Movistar.toLocaleString("de-DE", {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "11px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.msgQuantity.Movistar}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "120px",
                    alignContent: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    {(
                      parseInt(
                        calculatorData.msgQuantity.Movistar.replaceAll(".", "")
                      ) * calculatorData.prices.Movistar
                    ).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " USD"}
                  </Typography>
                </div>
              </div>

              <div className={classes.tableRow}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "start",
                    marginRight: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "15px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    Movilnet 416/426
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "86px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.prices.Movilnet.toLocaleString("de-DE", {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "135px",
                    alignContent: "center",
                    justifyContent: "center",
                    marginRight: "11px",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "#363636 !important",
                    }}
                    className="titleCard"
                  >
                    {calculatorData.msgQuantity.Movilnet}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "120px",
                    alignContent: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    sx={{
                      fontfamily: "Open Sans !important",
                      fontStyle: "normal !important",
                      fontWeight: "400 !important",
                      fontSize: "16px !important",
                      lineHeight: "normal !important",
                      letterSpacing: "0.15px !important",
                      color: "black !important",
                    }}
                    className="titleCard"
                  >
                    {(
                      parseInt(
                        calculatorData.msgQuantity.Movilnet.replaceAll(".", "")
                      ) * calculatorData.prices.Movilnet
                    ).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " USD"}
                  </Typography>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                padding: "0 68px 0 39px",
              }}
            >
              <div className={classes.hr}></div>
            </div>

            <div className={classes.resultRow}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "133px",
                  alignContent: "center",
                  justifyContent: "start",
                  marginLeft: "268px",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "black !important",
                  }}
                  className="titleCard"
                >
                  Total Cotización:
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "130px",
                  alignContent: "center",
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{
                    fontfamily: "Open Sans !important",
                    fontStyle: "normal !important",
                    fontWeight: "700 !important",
                    fontSize: "16px !important",
                    lineHeight: "normal !important",
                    letterSpacing: "0.15px !important",
                    color: "black !important",
                    ...(minimumBillingError && {
                      color: "#FF0606",
                    }),
                  }}
                  className="titleCard"
                >
                  {(
                    parseInt(
                      calculatorData.msgQuantity.Movilnet.replaceAll(".", "")
                    ) *
                      calculatorData.prices.Movilnet +
                    parseInt(
                      calculatorData.msgQuantity.Movistar.replaceAll(".", "")
                    ) *
                      calculatorData.prices.Movistar +
                    parseInt(
                      calculatorData.msgQuantity.Digitel.replaceAll(".", "")
                    ) *
                      calculatorData.prices.Digitel
                  ).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + " USD"}
                </Typography>
              </div>
            </div>
            <div className={classes.legalContainer}>
              <Typography
                sx={{
                  fontfamily: "Open Sans !important",
                  fontStyle: "normal !important",
                  fontWeight: "400 !important",
                  fontSize: "14px !important",
                  lineHeight: "26px !important",
                  letterSpacing: "0.15px !important",
                  color: "#363636 !important",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  "@media (max-width: 600px)": {
                    fontSize: "10px !important",
                  },
                }}
                className="legalText"
              >
                - Las tarifas mostradas en esta calculadora son referenciales,
                están sujetas a cambios y no incluyen el Impuesto al Valor
                Agregado (IVA).
              </Typography>
              <Typography
                sx={{
                  fontfamily: "Open Sans !important",
                  fontStyle: "normal !important",
                  fontWeight: "400 !important",
                  fontSize: "14px !important",
                  lineHeight: "26px !important",
                  letterSpacing: "0.15px !important",
                  width: "100%",
                  color: "#363636 !important",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  "@media (max-width: 600px)": {
                    fontSize: "10px !important",
                  },
                }}
                className="legalText"
              >
                - Pagaderos en bolívares a la tasa referencial del BCV.
              </Typography>
            </div>
            <button
              style={{
                ...(disabledDownload && {
                  backgroundColor: "rgba(27, 195, 221, 0.3)",
                  pointerEvents: "none",
                }),
              }}
              disabled={disabledDownload}
              className={classes.downloadButton}
              onClick={() => downloadPDF()}
            >
              <img src={downloadIcon} alt="|" width="16px" />
            </button>
          </div>
        </CardContent>
      </div>
    </div>
  )
}

CalculatorOutput.propTypes = {
  calculatorData: PropTypes.object.isRequired,
  sendMinimumBillingError: PropTypes.func.isRequired,
}

export default CalculatorOutput
