import LogoSpinmovil from "../../../../assets/Logo-Spinmovil.png"
import { makeStyles } from "@mui/styles"
import * as React from "react"
import CloseIcon from "@mui/icons-material/Close"

const useStyles = makeStyles(() => ({
  AppbarDiv: {
    position: "sticky",
    top: "0",
    zIndex: "1000",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.6em 5.5em",
    height: "fit-content",
    alignItems: "center",
    "@media (min-width: 1600px)": {
      padding: "0.5em 15em",
    },
    "@media (max-width: 800px)": {
      padding: "15px 23px 8px 20px",
      alignItems: "space-between",
      justifyContent: "center",
    },
    boxShadow: " 5px 5px 15px rgba(0, 0, 0, 0.1)",
    fontFamily: "Open Sans, sans-serif",
    backgroundColor: "white",
  },
  LogoSpinmovil: {
    height: "4.3em",
    padding: "0em 0",
    margin: "0em 0",
    "@media (max-width: 800px)": {
      marginBottom: "0em",
      height: "3.5em",
      left: "0",
    },
  },
  headerLogo: {
    display: "flex",
    width: "90%",
    height: "fit-content",
    margin: "0",
  },
  headerMenu: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    rowGap: "0.5em",
    columnGap: "1.9em",
    width: "100%",
    fontWeight: "600 !important",
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  menuItem: {
    height: "fit-content",
    margin: "0",
  },
  link: {
    cursor: "pointer",
  },
  headerContactButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "90%",
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  contactButton: {
    backgroundColor: "#1bc3dd",
    color: "white",
    fontWeight: "540 !important",
    fontSize: "16px",
    padding: "0em 1.3em",
    height: "2.3em",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
  },
  mobileHeaderMenu: {
    backgroundColor: "white",
    display: "none",
    "@media (max-width: 800px)": {
      position: "sticky",
      top: "11.1em",
      zIndex: "1000",
      flexDirection: "column",
      justifyContent: "start",
      rowGap: "0.5em",
      columnGap: "1.9em",
      width: "100%",
      fontWeight: "600 !important",
      height: "calc(calc(100vh - 11.1em) - 30px)",
      left: "0",
      paddingTop: "30px",
    },
  },
  mobileMenuItem: {
    height: "fit-content",
    width: "calc(100% - 60px)",
    margin: "0",
    padding: "0px 30px",
    cursor: "pointer",
  },
  mobileMenuButton: {
    display: "none",
    visibility: "hidden",
    flexDirection: "column",
    cursor: "pointer",
    height: "3.5em",
    width: "100%",
    justifyContent: "center",
    alignItems: "end",
    "@media (max-width: 800px)": {
      display: "flex",
      visibility: "visible",
    },
    "@media (min-width: 801px)": {
      display: "none",
    },
  },
  bar: {
    width: "20px",
    height: "2.5px",
    backgroundColor: "#8cceea",
    margin: "0 0 6px 0",
    padding: "0",
  },
  hr: {
    color: "#eaeaea",
    border: "1px solid #eaeaea",
    width: "calc(100% - 70px)",
  },
  mobileMenuCloseButton: {
    display: "flex",
    visibility: "hidden",
    flexDirection: "column",
    cursor: "pointer",
    height: "3.5em",
    width: "100%",
    justifyContent: "center",
    alignItems: "end",
    position: "absolute",
    top: "0",
    right: "18px",
    "@media (min-width: 801px)": {
      display: "none",
    },
  },
}))

const redirectSpinMovil = (path) => {
  window.location.href = process.env.REACT_APP_SPINMOVIL_LINK + path
}

const redirectContact = () => {
  window.location.href = process.env.REACT_APP_CONTACT_LINK
}

const resizeHeaderOnScroll = () => {
  try {
    if (window.innerWidth > 800) {
        const header = document.getElementById("header")
        if (header.style.padding != "15px 23px 8px 20px"){
            openMenu()
        }
    }
    
  } catch (error) {
    return;
  }
}

window.addEventListener("resize", resizeHeaderOnScroll)

const openMenu = () => {
  const openButton = document.getElementById("mobileMenuButton")
  const closeButton = document.getElementById("mobileMenuCloseButton")
  const mobileMenu = document.getElementById("mobileHeaderMenu")
  mobileMenu.style.display =
    mobileMenu.style.display === "flex" ? "none" : "flex"
  const header = document.getElementById("header")
  header.style.padding =
    mobileMenu.style.display === "flex"
      ? "50px 23px 60px 15px"
      : "15px 23px 8px 20px"
  header.style.boxShadow =
    mobileMenu.style.display === "flex"
      ? "none"
      : "5px 5px 15px rgba(0, 0, 0, 0.1)"
  const logo = document.getElementById("spinmovilLogo")
  logo.style.height = mobileMenu.style.display === "flex" ? "4em" : "3.5em"
  openButton.style.visibility =
    mobileMenu.style.display === "flex" ? "hidden" : "visible"
  closeButton.style.visibility =
    mobileMenu.style.display === "flex" ? "visible" : "hidden"
}

const RatesLandingHeader = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.AppbarDiv} id="header">
        <div className={classes.headerLogo}>
          <a onClick={() => redirectSpinMovil("")} className={classes.link}>
            <img
              src={LogoSpinmovil}
              alt="SpinMovil Logo"
              className={classes.LogoSpinmovil}
              id="spinmovilLogo"
            />
          </a>
        </div>
        <div className={classes.headerMenu}>
          <div className={classes.menuItem}>
            <a onClick={() => redirectSpinMovil("")} className={classes.link}>
              Home
            </a>
          </div>
          <div className={classes.menuItem}>
            <a
              onClick={() => redirectSpinMovil("#nosotros")}
              className={classes.link}
            >
              Nosotros
            </a>
          </div>
          <div className={classes.menuItem}>
            <a
              onClick={() => redirectSpinMovil("#servicios")}
              className={classes.link}
            >
              Servicios
            </a>
          </div>
          <div className={classes.menuItem}>
            <a
              onClick={() => redirectSpinMovil("/blog/")}
              className={classes.link}
            >
              Blog
            </a>
          </div>
          <div className={classes.menuItem}>
            <a
              onClick={() => redirectSpinMovil("#contacto")}
              className={classes.link}
            >
              Contáctanos
            </a>
          </div>
        </div>

        <div
          className={classes.mobileMenuButton}
          onClick={openMenu}
          id="mobileMenuButton"
        >
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
        </div>

        <div
          className={classes.mobileMenuCloseButton}
          onClick={openMenu}
          id="mobileMenuCloseButton"
        >
          <CloseIcon sx={{ fontSize: "18px" }} />
        </div>

        <div className={classes.headerContactButton}>
          <button
            className={classes.contactButton}
            onClick={() => redirectContact()}
          >
            Hablemos
          </button>
        </div>
      </div>

      <div className={classes.mobileHeaderMenu} id="mobileHeaderMenu">
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectSpinMovil("")}
        >
          <a className={classes.link}>Home</a>
        </div>
        <hr className={classes.hr} />
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectSpinMovil("#nosotros")}
        >
          <a className={classes.link}>Nosotros</a>
        </div>
        <hr className={classes.hr} />
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectSpinMovil("#servicios")}
        >
          <a className={classes.link}>Servicios</a>
        </div>
        <hr className={classes.hr} />
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectSpinMovil("/blog/")}
        >
          <a className={classes.link}>Blog</a>
        </div>
        <hr className={classes.hr} />
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectSpinMovil("#contacto")}
        >
          <a className={classes.link}>Contáctanos</a>
        </div>
        <hr className={classes.hr} />
        <div
          className={classes.mobileMenuItem}
          onClick={() => redirectContact()}
        >
          <a className={classes.link}>Hablemos</a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RatesLandingHeader
