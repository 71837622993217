import React from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useForm, Controller } from "react-hook-form"
import {
  createRate,
  getAllBusiness,
  getServiceProvidersBusiness,
} from "../../services/api"

import { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { useApp } from "../../../../AppProvider"
import { Typography } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import LoadingDialog from "../../../../components/LoadingDialog/LoadingDialog"

const classUseStyles = makeStyles(() => ({
  baseActionButton: {
    fontSize: "14px !important",
    minWidth: "100px !important",
    fontWeight: "600 !important",
    backgroundColor: "#FFFFFF !important",
    border: "#3DB76E 1px solid !important",
    borderRadius: "8px !important",
    color: "#3DB76E !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#3DB76E !important",
    color: "white !important",
  },
}))

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "black",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      padding: 0,
      margin: 0,
    },
  },
}))

const NewRateModal = ({ open, setOpen, callback }) => {
  const [errorMovilnetText, setErrorMovilnetText] = useState("")
  const [errorMovistarText, setErrorMovistarText] = useState("")
  const [errorDigitelText, setErrorDigitelText] = useState("")
  const [errorMinimumBillingText, setErrorMinimumBillingText] = useState("")
  const [errorVigencyText, setErrorVigencyText] = useState("")
  const [errorBusinessesText, setErrorBusinessesText] = useState("")

  const [disabledMovilnet, setDisabledMovilnet] = useState(true)
  const [disabledMovistar, setDisabledMovistar] = useState(true)
  const [disabledDigitel, setDisabledDigitel] = useState(true)

  const { showNotification } = useApp()

  const [campoempresa, setCampoempresa] = useState(null)
  const [inputCampoempresa, setInputCampoempresa] = useState("")

  const [businessesList, setBusinessesList] = useState([])

  const [loadingCreateRate, setLoadingCreateRate] = useState(false)

  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  useEffect(() => {
    setBusinesses([])

    const fetchData = async () => {
      const existenceBusiness = await getAllBusiness()
      setBusinessesList(existenceBusiness.data)
    }
    fetchData()
  }, [open])

  const formClasses = classUseStyles()
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      movistar: "0",
      movilnet: "0",
      digitel: "0",
      vigency: tomorrow.toISOString().split("T")[0],
      minimumBilling: "0",
    },
  })

  const [businesses, setBusinesses] = useState([])

  const useStylesOption = makeStyles({
    option: {
      "&:hover": {
        backgroundColor: "#DAE6F1 !important",
        color: "#0957A0",
        font: "Open Sans",
        size: "16px",
      },
    },
  })

  const useStyles = makeStyles(() => ({
    longitudIcon: {
      "& svg": {
        width: "24px",
        height: "22px",
      },
    },
  }))

  const textInput = React.useRef(null)

  const onSubmit = async (data) => {
    try {
      var algunerror = ""
      data.movistar = data.movistar.replace(",", ".")
      data.digitel = data.digitel.replace(",", ".")
      data.movilnet = data.movilnet.replace(",", ".")
      data.minimumBilling = data.minimumBilling.replace(",", ".")
      if (isNaN(parseFloat(data.movistar))) {
        setErrorMovistarText("Debe ser un número")
        algunerror = "e"
      }

      if (isNaN(parseFloat(data.digitel))) {
        setErrorDigitelText("Debe ser un número")
        algunerror = "e"
      }

      if (isNaN(parseFloat(data.movilnet))) {
        setErrorMovilnetText("Debe ser un número")
        algunerror = "e"
      }

      if (isNaN(parseFloat(data.minimumBilling))) {
        setErrorMinimumBillingText("Debe ser un número")
        algunerror = "e"
      }

      if (
        (!data.movistar || parseFloat(data.movistar) <= 0) &&
        disabledMovistar === false
      ) {
        setErrorMovistarText("Debe ser mayor a 0")
        algunerror = "e"
      }

      if (
        (!data.digitel || parseFloat(data.digitel) <= 0) &&
        disabledDigitel === false
      ) {
        setErrorDigitelText("Debe ser mayor a 0")
        algunerror = "e"
      }

      if (
        (!data.movilnet || parseFloat(data.movilnet) <= 0) &&
        disabledMovilnet === false
      ) {
        setErrorMovilnetText("Debe ser mayor a 0")
        algunerror = "e"
      }

      if (
        data.minimumBilling == undefined ||
        parseFloat(data.minimumBilling) < 0
      ) {
        setErrorMinimumBillingText("Debe ser positiva")
        algunerror = "e"
      }

      if (!data.vigency) {
        setErrorVigencyText("Debe haber una fecha de vigencia")
        algunerror = "e"
      }

      if (businesses.length === 0) {
        setErrorBusinessesText("Debe seleccionar al menos una empresa")
        algunerror = "e"
      }

      if (algunerror === "") {
        setLoadingCreateRate(true)
        await createRate(
          parseFloat(data.movistar),
          parseFloat(data.digitel),
          parseFloat(data.movilnet),
          data.vigency,
          parseFloat(data.minimumBilling),
          businesses.map((item) => {
            return { id: item.id }
          })
        )
        setLoadingCreateRate(false)
        callback()
        handleClose()
        showNotification("Tarifas creadas exitosamente", "success")
      }
    } catch (error) {
      setLoadingCreateRate(false)
      if (error.response === undefined) {
        showNotification(
          "Lo sentimos se ha producido un error inesperado al crear la tarifa",
          "error"
        )
      } else {
        showNotification(error.response.data.message, "error")
      }
    }
  }

  const handleClose = () => {
    reset()
    setBusinesses([])
    setOpen(false)
    callback()
  }

  const DialogActionspadding = {
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  }

  const modalestilo = {
    overflow: "auto !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    width: "530px !important",
    maxWidth: "530px !important",
    borderRadius: "8px",
    height: "fit-content",
    padding: "0px 0px 10px 0px",
  }

  const textotitulomodal = {
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(78, 85, 137, 1)",
    marginTop: "8px",
    marginLeft: "-10px",
    marginBottom: "6px",
    textAlign: "center",
  }

  const formaestilo = {
    borderTop: "1px solid #979797",
  }

  const dialogoestilo = {
    margin: "0 1.8em 0 1.8em",
    height: "fit-content",
  }

  const iconobucador = {
    color: "#0957A0",
    marginRight: "8px",
    fontSize: "28px",
  }

  const stylesoption = useStylesOption()

  const handleFocusMovilnet = (event) => {
    event.preventDefault()
    const { target } = event
    setErrorMovilnetText("")
  }

  const handleFocusMovistar = (event) => {
    event.preventDefault()
    const { target } = event
    setErrorMovistarText("")
  }

  const handleFocusDigitel = (event) => {
    event.preventDefault()
    const { target } = event
    setErrorDigitelText("")
  }

  const handleFocusMinimumBilling = (event) => {
    event.preventDefault()
    const { target } = event
    setErrorMinimumBillingText("")
  }

  const handleFocusBusinesses = (event) => {
    event.preventDefault()
    const { target } = event
    setErrorBusinessesText("")
  }

  function clearErrors() {
    setErrorMovilnetText("")
    setErrorMovistarText("")
    setErrorDigitelText("")
    setErrorMinimumBillingText("")
    setErrorVigencyText("")
    setErrorBusinessesText("")
  }

  function addBusiness(e, value) {
    clearErrors()
    setCampoempresa(null)
    setInputCampoempresa("")
    setBusinesses([
      ...businesses,
      {
        id: value.id,
        rif: value.rif,
        direccion: value.direccion,
        razonSocial: value.razonSocial,
        telefono: value.telefono,
        estatus: value.estatus,
      },
    ])
  }

  const checkServiceProviders = async (business) => {
    const serviceProviders = await getServiceProvidersBusiness(business.id)

    serviceProviders.data.forEach((serviceProvider) => {
      if (serviceProvider.nombre.toLowerCase() == "movistar") {
        setDisabledMovistar(false)
      }
      if (serviceProvider.nombre.toLowerCase() == "movilnet") {
        setDisabledMovilnet(false)
      }
      if (serviceProvider.nombre.toLowerCase() == "digitel") {
        setDisabledDigitel(false)
      }
    })
    setValue("movistar", "0")
    setValue("movilnet", "0")
    setValue("digitel", "0")
  }

  const handleAddBusiness = (event, value) => {
    var contidemp = 0

    setCampoempresa(null)
    setInputCampoempresa("")
    businesses.map((item, index2) => {
      if (item.id === value.id) {
        showNotification(item.razonSocial + " ya seleccionada", "error")
        contidemp = contidemp + 1
      }
    })
    if (contidemp === 0) {
      if (businesses.length === 0) {
        setDisabledMovistar(true)
        setDisabledMovilnet(true)
        setDisabledDigitel(true)
        checkServiceProviders(value)
      } else {
        setDisabledMovistar(false)
        setDisabledMovilnet(false)
        setDisabledDigitel(false)
      }
      addBusiness(event, value)
    }
  }

  const handleRemoveBusiness = (idx, value, campo) => {
    clearErrors()
    // assigning the list to temp variable
    const temp = [...businesses]

    //alert(idx + " -- " + value + " - - " + campo);

    // removing the element using splice
    temp.splice(idx, 1)

    if (businesses.length === 2) {
      setDisabledMovistar(true)
      setDisabledMovilnet(true)
      setDisabledDigitel(true)
      checkServiceProviders(temp[0])
    } else {
      setDisabledMovistar(false)
      setDisabledMovilnet(false)
      setDisabledDigitel(false)
    }

    // updating the list
    setBusinesses(temp)

    setCampoempresa(null)
    setInputCampoempresa("")
  }

  const helperTestClasses = helperTextStyles()


  const handleInputChange = (event) => {
    let { name, value } = event.target
    value = value.replace(".", ",")
    setValue(name, value)
  }
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: modalestilo,
      }}
    >
      <DialogTitle>
        <div>
          <h2 style={textotitulomodal}>{"Crear Tarifa"}</h2>
        </div>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} id="datos" style={formaestilo}>
        <DialogContent style={dialogoestilo}>
          <Autocomplete
            id="combo-box-demo"
            options={businessesList}
            classes={{
              option: stylesoption.option,
            }}
            ListboxProps={{ style: { maxHeight: "6.8rem" } }}
            getOptionLabel={(option) => option.razonSocial}
            clearOnBlur
            selectOnFocus
            disableClearable
            freeSolo
            noOptionsText="No se han encontrado opciones"
            forcePopupIcon={false}
            onChange={handleAddBusiness}
            onInputChange={(event, newInputValue) => {
              setInputCampoempresa(newInputValue)
            }}
            inputValue={inputCampoempresa}
            value={campoempresa}
            blurOnSelect={true}
            sx={{
              "& label": {
                color: "rgba(0, 0, 0, 0.87)",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              },
              height: "fit-content",
              marginBottom: "10px",
              width: "80%",
              marginLeft: 6.5,
            }}
            renderInput={(params) => (
              <TextField
                id="vempresa"
                error={errorBusinessesText !== ""}
                helperText={errorBusinessesText}
                onFocus={handleFocusBusinesses}
                {...params}
                label="Empresa"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon style={iconobucador} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              gap: "5px",
              marginTop: "0",
              marginBottom: "10px",
              height: "fit-content",
              width: "100%",
            }}
          >
            <React.Fragment>
              {businesses.map((itemempre, index) => (
                <Button
                  key={index}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: "500 !important",
                    backgroundColor: "#caccdc !important",
                    border: "none !important",
                    borderRadius: "6px !important",
                    color: "black !important",
                    textTransform: "none !important",
                    "@media screen and (maxWidth: 400px)": {
                      fontSize: "0.4rem",
                    },
                    fontFamily: "Open Sans !important",
                  }}
                  endIcon={
                    <CloseIcon
                      onClick={() =>
                        handleRemoveBusiness(
                          index,
                          itemempre.razonSocial,
                          campoempresa
                        )
                      }
                      style={{
                        fontSize: "12px",
                      }}
                    />
                  }
                >
                  &nbsp;&nbsp;{itemempre.razonSocial}
                </Button>
              ))}
            </React.Fragment>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "17px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
                marginTop: "0",
                paddingTop: "0",
                marginLeft: "6.5px",
                marginBottom: "20px",
              }}
            >
              Monto por Operadoras
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Digitel <strong>412 / 422</strong>
            </Typography>
            <Controller
              name="digitel"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="digitel"
                  inputRef={textInput}
                  onFocus={handleFocusDigitel}
                  disabled={disabledDigitel}
                  inputProps={{ maxLength: 5 }}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      ...(!disabledDigitel && {
                        "& > fieldset": {
                          borderColor: "#3db76e",
                        },
                      }),
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      ...(!disabledDigitel && {
                        WebkitTextFillColor: "#000000",
                      }),
                    },
                    "& .MuiOutlinedInput-root ": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      fieldset: {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                    },
                    color: "rgba(0, 0, 0, 0.87) !important",
                    height: "40px",
                    width: "40%",
                    textAlignLast: "center",
                  }}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={errorDigitelText !== ""}
                  helperText={errorDigitelText}
                  {...field}
                  onChange={handleInputChange}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Movistar <strong>414 / 424</strong>
            </Typography>
            <Controller
              name="movistar"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  id="movistar"
                  inputRef={textInput}
                  onFocus={handleFocusMovistar}
                  disabled={disabledMovistar}
                  inputProps={{ maxLength: 5 }}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      ...(!disabledMovistar && {
                        "& > fieldset": {
                          borderColor: "#3db76e",
                        },
                      }),
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      ...(!disabledMovistar && {
                        WebkitTextFillColor: "#000000",
                      }),
                    },
                    "& .MuiOutlinedInput-root ": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      fieldset: {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                    },
                    color: "rgba(0, 0, 0, 0.87) !important",
                    height: "40px",
                    width: "40%",
                    textAlignLast: "center",
                  }}
                  type="text"
                  fullWidth
                  variant="outlined"
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  size="small"
                  error={errorMovistarText !== ""}
                  helperText={errorMovistarText}
                  {...field}
                  onChange={handleInputChange}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "25px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Movilnet <strong>416 / 426</strong>
            </Typography>
            <Controller
              name="movilnet"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  id="movilnet"
                  inputRef={textInput}
                  onFocus={handleFocusMovilnet}
                  disabled={disabledMovilnet}
                  inputProps={{ maxLength: 5 }}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      ...(!disabledMovilnet && {
                        "& > fieldset": {
                          borderColor: "#3db76e",
                        },
                      }),
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      ...(!disabledMovilnet && {
                        WebkitTextFillColor: "#000000",
                      }),
                    },
                    "& .MuiOutlinedInput-root ": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      fieldset: {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                    },
                    color: "rgba(0, 0, 0, 0.87) !important",
                    height: "40px",
                    width: "40%",
                    textAlignLast: "center",
                  }}
                  type="text"
                  fullWidth
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  variant="outlined"
                  size="small"
                  error={errorMovilnetText !== ""}
                  helperText={errorMovilnetText}
                  {...field}
                  onChange={handleInputChange}
                />
              )}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Controller
              name="vigency"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  id="vigency"
                  inputRef={textInput}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                    "& .MuiOutlinedInput-root ": {
                      "&.Mui-focused fieldset": {
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      fieldset: {
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      height: "40px",
                    },
                    "& label": {
                      color: "black",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                    },
                    height: "38px",
                    width: "100%",
                    marginTop: 0.0,
                  }}
                  label="Válido a partir de:"
                  type="date"
                  format="YYYY/MM/DD"
                  inputProps={{ min: tomorrow.toISOString().split("T")[0] }}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  variant="outlined"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "La fecha debe ser mayor a la de hoy."
                    )
                  }}
                  onFocus={(e) => {
                    if (new Date(e.target.value) >= new Date()) {
                      e.target.setCustomValidity("")
                    }
                  }}
                  onKeyDown={(e) => {
                    if (new Date(e.target.value) >= new Date()) {
                      e.target.setCustomValidity("")
                    }
                  }}
                  onKeyUp={(e) => {
                    if (new Date(e.target.value) >= new Date()) {
                      e.target.setCustomValidity("")
                    }
                  }}
                  error={errorVigencyText !== ""}
                  helperText={errorVigencyText}
                  {...field}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "fit-content",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Facturación Mínima en USD
            </Typography>

            <Controller
              name="minimumBilling"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  id="minimumBilling"
                  inputRef={textInput}
                  onFocus={handleFocusMinimumBilling}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      "& > fieldset": {
                        borderColor: "#3db76e",
                      },
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                    "& .MuiOutlinedInput-root ": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                      fieldset: {
                        borderColor: "#3db76e",
                        borderRadius: "10px",
                        borderWidth: "2px",
                      },
                    },
                    color: "rgba(0, 0, 0, 0.87) !important",
                    height: "40px",
                    width: "40%",
                    textAlignLast: "center",
                  }}
                  type="text"
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  variant="outlined"
                  size="small"
                  error={errorMinimumBillingText !== ""}
                  helperText={errorMinimumBillingText}
                  {...field}
                  onChange={handleInputChange}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "0",
              margin: "0",
              width: "80%",
              height: "fit-content",
              fontSize: "10px",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            campo opcional
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                letterSpacing: "0.15px",
                color: "#737373",
                marginTop: "0",
                paddingTop: "0",
              }}
            >
              Los montos se encuentran expresados en dólares americanos.
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#737373",
                marginTop: "0",
                paddingTop: "0",
              }}
            >
              Las tarifas no incluyen impuestos, son pagaderas a tasa del B.C.V.
            </Typography>
          </div>
        </DialogContent>

        <DialogActions style={DialogActionspadding}>
          <React.Fragment>
            <Button
              type="button"
              className={formClasses.baseActionButton}
              variant="contained"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              className={`${formClasses.baseActionButton} ${formClasses.nextActionButton}`}
              variant="contained"
            >
              Guardar
            </Button>
          </React.Fragment>
        </DialogActions>
      </form>
      <LoadingDialog shouldOpen={loadingCreateRate} />
    </Dialog>
  )
}

NewRateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
}

export default NewRateModal
