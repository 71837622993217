import { makeStyles } from "@mui/styles"
import * as React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import PropTypes from "prop-types"
import { Typography, Input, Button, TextField } from "@mui/material"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "./index.css"

const useStyles = makeStyles(() => ({
  inputText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "black",
    margin: "0",
    padding: "0.3em 0",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "start",
    width: "calc(100% - 70px)",
    padding: "5px 0 0 30px",
  },
  errorText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "15px",
    fontWeight: "400",
    color: "#d94141",
    margin: "0",
    padding: "0",
    height: "3.5em",
  },
  field: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "none",
    textAlign: "end",
    height: "58px",
    width: "147px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 20px 0 20px",
    "&:focus": {
      outline: "none",
    },
  },
  errorField: {
    border: "2px solid #d94141",
    backgroundColor: "white",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "none",
    textAlign: "end",
    height: "58px",
    width: "147px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 20px 0 20px",
    color: "red",
    "&:focus": {
      outline: "none",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  inputField: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
  inputButtons: {
    display: "flex",
    flexDirection: "column",

    width: "40px",
  },
  inputButton1: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderBottom: "none",
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    cursor: "pointer",
    
  },
  inputButton2: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderTop: "none",
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },
  inputButton1Error: {
    backgroundColor: "white",
    border: "2px solid #d94141",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderBottom: "none",
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    cursor: "pointer",
    
  },
  inputButton2Error: {
    backgroundColor: "white",
    border: "2px solid #d94141",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderTop: "none",
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },

}))

const CalculatorInput = ({ sendCalculatorData, pricesPerMessage}) => {
  
  const [msgQuantity, setMsgQuantity] = React.useState("1.000")
  const [msgQuantityError, setMsgQuantityError] = React.useState("")

  const [planType, setPlanType] = React.useState("BasicRate")

  const changePlanTypeHandler = (event) => {
    setPlanType(event.target.value)
  }

  const changeMsgQuantityHandler = (event) => {
    if (isNaN(parseInt(event.target.value.replaceAll('.', ''))) && event.target.value !== "") {
      return
    }else {
      setMsgQuantity(event.target.value !== "" ? 
          (parseInt(event.target.value.replaceAll('.', ''))).toLocaleString('de-DE'): "")
    }
  }

  React.useEffect(() => {
    if (parseInt(msgQuantity.replaceAll(".","")) < 1000 || msgQuantity === "") {
      setMsgQuantityError("Mínimo 1.000")
    }else if (parseInt(msgQuantity.replaceAll(".","")) > 5000000) {
      setMsgQuantityError("Máximo 5.000.000")
    }else {
      setPlanType(parseInt(msgQuantity.replaceAll(".","")) > 50000 ? "CorporativeRate" : "BasicRate")
      sendCalculatorData({
        msgQuantity: Number(parseInt(msgQuantity.replaceAll(".",""))),
        pricePerMessage:
          pricesPerMessage[
            parseInt(msgQuantity.replaceAll(".","")) > 50000 ? "CorporativeRate" : "BasicRate"
          ],
        plan: parseInt(msgQuantity.replaceAll(".","")) > 50000 ? "CorporativeRate" : "BasicRate",
      })
      setMsgQuantityError("")
    }
  }, [msgQuantity])

  React.useEffect(() => {
    sendCalculatorData({
      msgQuantity: Number(parseInt(msgQuantity.replaceAll(".",""))),
      pricePerMessage: pricesPerMessage[planType],
      plan: planType,
    })
  }, [pricesPerMessage])

  const classes = useStyles()

  return (
    <FormControl
      sx={{
        width: "calc(100% - 80px)",
        display: "flex",
        flexDirection: "column",
        padding: "15px 40px 20px 40px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontfamily: "Open Sans !important",
          fontStyle: "normal !important",
          fontWeight: "700 !important",
          fontSize: "30px !important",
          lineHeight: "40px !important",
          letterSpacing: "0.15px !important",
          color: "black !important",
          marginBottom: "35px",
          fontFeatureSettings: "'clig' off, 'liga' off",
          "@media (max-width: 450px)": {
            fontSize: "25px !important",
          },
        }}
      >
        Calcula tu próxima campaña
      </Typography>
      <RadioGroup
        aria-labelledby="plan-type-radio-buttons-group-label"
        defaultValue="BasicRate"
        name="radio-buttons-group"
        onChange={changePlanTypeHandler}
        sx={{
          marginBottom: "25px",
        }}
      >
        <FormControlLabel
          className={classes.inputText}
          sx={{
            color: "#4E5589 !important",
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "black !important",
            },
            "& .MuiFormControlLabel-label": {
              fontSize: "25px",
              fontWeight: "350",
            },
          }}
          value="BasicRate"
          control={
            <Radio
              sx={{
                "&.Mui-disabled": {
                  color: "black",
                },
                "&.Mui-checked": {
                  color: "#1BC3DD !important",
                },
              }}
              disabled
              checked={planType === "BasicRate"}
            />
          }
          label="Plan Básico"
        />
        <FormControlLabel
          className={classes.inputText}
          sx={{
            color: "#4E5589 !important",
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "black !important",
            },
            "& .MuiFormControlLabel-label": {
              fontSize: "25px",
              fontWeight: "350",
            },
          }}
          value="CorporativeRate"
          control={
            <Radio
              sx={{
                "&.Mui-disabled": {
                  color: "black",
                },
                "&.Mui-checked": {
                  color: "#1BC3DD !important",
                },
              }}
              checked={planType === "CorporativeRate"}
              disabled
            />
          }
          label="Plan Corporativo"
        />
      </RadioGroup>
      <div className={classes.inputContainer}>
        <Typography
          variant="h3"
          sx={{
            fontfamily: "Open Sans !important",
            fontStyle: "normal !important",
            fontWeight: "350 !important",
            fontSize: "18px !important",
            letterSpacing: "0.15px !important",
            color: "black !important",
            marginTop: "0.2em",
            marginBottom: "23px",
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
        >
          Cantidad de Mensajes a Cotizar
        </Typography>
        <div className={classes.inputField}>
          <input
            value={msgQuantity}
            onChange={changeMsgQuantityHandler}
            className={`${
              msgQuantityError == "" ? classes.field : classes.errorField
            } `}
          />
          <div className={classes.inputButtons}>
            <div
              onClick={() => setMsgQuantity((parseInt(msgQuantity.replaceAll(".","")) + 1).toLocaleString('de-DE'))}
              className={msgQuantityError == "" ? classes.inputButton1 : classes.inputButton1Error}
            >
              <ArrowDropUpIcon sx={{margin:"0", padding:"0", fontSize:"25px", position: "relative", top: "7px"}}/>
            </div>
            <div
              onClick={() => setMsgQuantity((parseInt(msgQuantity.replaceAll(".","")) - 1).toLocaleString('de-DE'))}
              className={msgQuantityError == "" ? classes.inputButton2 : classes.inputButton2Error}
            >
              <ArrowDropDownIcon sx={{margin:"0", padding:"0", fontSize:"25px", position: "relative", bottom: "7px"}}/>
            </div>

          </div>

        </div>
        <div className={classes.errorContainer}>
          <p className={classes.errorText}>{msgQuantityError}</p>
        </div>
      </div>
    </FormControl>
  )
}

CalculatorInput.propTypes = {
  sendCalculatorData: PropTypes.func.isRequired,
  pricesPerMessage: PropTypes.object.isRequired,
}

export default CalculatorInput
