import RatesHeader from "./components/header"
import RatesFooter from "./components/footer"
import CalculatorInput from "./components/calculatorInput"
import CalculatorOutput from "./components/calculatorOutput"
import { makeStyles } from "@mui/styles"
import * as React from "react"
import { Card, CardContent, Typography } from "@mui/material"
import LogoSpinmovilHeader from "../../assets/Logo-Spinmovil-header-white.ico"
import RateCard from "./components/rateCard"
import { getWebRates } from "./services/api"
import { useApp } from "../../AppProvider"

const useStyles = makeStyles(() => ({
  divContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "72%",
    height: "fit-content",
    gap: "2em",
    padding: "3em 14%",
    "@media (min-width: 1600px)": {
      padding: "3em 20%",
      width: "60%",
    },
    "@media (max-width: 800px)": {
      padding: "3em 10%",
      width: "80%",
    },
  },
  divItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "403px",
    height: "fit-content",
    gap: "1em",
    backgroundColor: "white",
    borderRadius: "1em",
    border: "1px solid black",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100%-20px)",
    height: "fit-content",
    padding: "50px 10px 20px 10px",
    gap: "25px",
  },
}))

const RatesLanding = () => {
  const { showNotification } = useApp()
  const classes = useStyles()
  document.title = "SpinMóvil. Una división de Conectium"
  document.getElementById("document-icon").href = LogoSpinmovilHeader

  const [calculatorData, setCalculatorData] = React.useState({
    msgQuantity: 0,
    pricePerMessage: 0,
  })

  const [rates, setRates] = React.useState({
    BasicRate: 0,
    CorporativeRate: 0,
  })

  const [CorporateRate, setCorporateRate] = React.useState(0)
  const [BasicRate, setBasicRate] = React.useState(0)

  const fetchRates = React.useCallback(async () => {
    try {
      const response = await getWebRates()
      const data = response.data
      setRates(data)
    } catch (e) {
      console.log(e)
      showNotification(
        "Lo sentimos, se ha producido un error inesperado al obtener las Tarifas",
        "error"
      )
    }
  })

  React.useEffect(() => {
    fetchRates()
  }, [])

  React.useEffect(() => {
    setBasicRate(rates.BasicRate)
    setCorporateRate(rates.CorporativeRate)
  }, [rates])

  const ReceiveCalculatorData = (calculatorData) => {
    setCalculatorData(calculatorData)
  }

  const redirectContact = () => {
    window.location.href = process.env.REACT_APP_CONTACT_LINK
  }

  return (
    <React.Fragment>
      <RatesHeader />
      <div className={classes.titleContainer}>
        <Typography
          sx={{
            fontfamily: "Open Sans !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "30px !important",
            lineHeight: "32px !important",
            letterSpacing: "0.15px !important",
            color: "rgba(54, 54, 54, 1) !important",
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
          className="title"
        >
          Tarifas
        </Typography>
        <Typography
          sx={{
            fontfamily: "Open Sans !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "16px !important",
            lineHeight: "18px !important",
            letterSpacing: "0.15px !important",
            color: "rgba(54, 54, 54, 1) !important",
            fontFeatureSettings: "'clig' off, 'liga' off",
            textAlign: "center",
          }}
          className="title"
        >
          Descubre las tarifas de nuestros paquetes de mensajes SMS para
          potenciar tus campañas publicitarias con Spinmóvil.
        </Typography>
      </div>
      <div className={classes.divContainer} id="ratesContainer">
        <RateCard
          title="Plan Básico"
          subtitle="Desde 1.000 hasta 50.000 SMS"
          rate={BasicRate.toLocaleString("de-De", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
        />
        <RateCard
          title="Plan Corporativo"
          subtitle="A partir de 50.001 SMS"
          rate={CorporateRate.toLocaleString("de-De", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
        />

        <div
          style={{
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "403px",
            minWidth: "300px",
            height: "fit-content",
            gap: "1em",
            backgroundColor: "white",
            border: "1px solid black",
          }}
        >
          <CardContent>
            <CalculatorInput
              sendCalculatorData={ReceiveCalculatorData}
              pricesPerMessage={rates}
            />
          </CardContent>
        </div>

        <CalculatorOutput calculatorData={calculatorData} />
      </div>
      <RatesFooter />
    </React.Fragment>
  )
}

export default RatesLanding
