import { makeStyles } from "@mui/styles"
import * as React from "react"
import { Card, CardContent, Typography, Divider, Button } from "@mui/material"
import PropTypes from "prop-types"

import "./index.css"

const useStyles = makeStyles(() => ({
  divItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "403px",
    height: "fit-content",
    gap: "1em",
    backgroundColor: "#1bc3dd",
    paddingBottom: "35px",
    border: "1px solid black",
  },
  divTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "0.5em",
    backgroundColor: "rgba(121, 102, 217, 1)",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: "30px 0 20px 0",
  },
  divBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "calc(100% - 30px)",
    height: "100%",
    padding: "50px 15px 0 15px",
  },
}))

const RateCard = ({ title, subtitle, rate }) => {
  const classes = useStyles()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "403px",
        minWidth: "300px",
        height: "fit-content",
        gap: "1em",
        paddingBottom: "35px",
        border: "1px solid black",
        borderRadius: "20px",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        <div className={classes.divTitle}>
          <Typography
            variant="h2"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "30px !important",
              letterSpacing: "0.15px !important",
              color: "white !important",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "350 !important",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "white !important",

              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {subtitle}
          </Typography>
        </div>
        <div className={classes.divBody}>
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "400 !important",
              fontSize: "16 !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            Tarifa Por Mensaje
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "40px !important",
              letterSpacing: "0.15px !important",
              color: "#7966D9 !important",
              marginBottom: "35px",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {rate} USD
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important",
              fontSize: "20px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "0",
              marginBottom: "10px",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            Panel de control
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important",
              fontSize: "20px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "0",
              marginBottom: "10px",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            Soporte disponible
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important",
              fontSize: "20px !important",
              letterSpacing: "0.15px !important",
              color: "#363636 !important",
              marginTop: "0",
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            Asesoría en los envíos
          </Typography>
        </div>
      </CardContent>
    </div>
  )
}

RateCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
}

export default RateCard
