import CalculatorInput from "./components/calculatorInput"
import CalculatorOutput from "./components/calculatorOutput"
import { makeStyles } from "@mui/styles"
import * as React from "react"
import { Card, CardContent, Typography, Select, MenuItem } from "@mui/material"
import { getUserBusinessRates } from "./services/api"
import HeadSectionComponent from "../../components/SectionTable/HeadSection"
import { useApp } from "../../AppProvider"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const useStyles = makeStyles(() => ({
  divContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    height: "fit-content",
    gap: "2em",
  },
  divItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "409px",
    height: "425px",
    backgroundColor: "white",
    borderRadius: "20px",
    border: "1px solid black",
  },
  selectContainer: {
    display: "flex",
    justifyContent: "end",
    width: "1115px",
    height: "fit-content",
    position: "absolute",
    top: "138px",
  },
  select: {
    height: "30px",
    borderRadius: "5px",
    backgroundColor: "white",
    color: "black",
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "24px",
      fontFamily: "Open Sans !important",
    },
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiSvgIcon-root": {
      color: "#4E5589",
      fontSize: "30px",

      borderLeft: "1px solid #C4C4C4",

      borderRadius: "1",
      borderColor: "transparent",
    },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      paddingLeft: "10px",
      paddingTop: "5px",
    },
    "&:click ": {
      color: "red",
    },
  },
}))

const RatesCalculator = () => {
  const { showNotification } = useApp()
  const classes = useStyles()

  const [calculatorData, setCalculatorData] = React.useState({
    msgQuantity: {
      Digitel: "0",
      Movistar: "0",
      Movilnet: "0",
      Total: "0",
    },
    prices: {
      Digitel: 0,
      Movistar: 0,
      Movilnet: 0,
      MinimumBilling: 0,
    },
  })

  const [rates, setRates] = React.useState({
    Digitel: 0,
    Movistar: 0,
    Movilnet: 0,
    MinimumBilling: 0,
  })

  const [business, setBusiness] = React.useState(0)

  const [businessRates, setBusinessRates] = React.useState([])

  const [minimumBillingError, setMinimumBillingError] = React.useState("")

  const [notification, setNotification] = React.useState(false)

  const fetchRates = React.useCallback(async () => {
    try {
      const response = await getUserBusinessRates()
      const data = response.data
      setBusinessRates(data)
      if (data.length > 0) {
        setBusiness(data[0].business.id)
      }
    } catch (e) {
      console.log(e)
      showNotification(
        "Lo sentimos, se ha producido un error inesperado al obtener las Tarifas",
        "error"
      )
    }
  })

  React.useEffect(() => {
    fetchRates()
  }, [])

  React.useEffect(() => {
    if (business == 0) {
      setRates({
        Digitel: 0,
        Movistar: 0,
        Movilnet: 0,
        MinimumBilling: 0,
      })
    } else if (businessRates.length > 0) {
      const rates = businessRates.find((br) => br.business.id === business)

      setRates({
        Digitel: rates.DigitelRate,
        Movistar: rates.MovistarRate,
        Movilnet: rates.MovilnetRate,
        MinimumBilling: rates.MinimumBilling,
      })
    }
  }, [business])

  const ReceiveCalculatorData = (calculatorData) => {
    setCalculatorData({ ...calculatorData, prices: rates })
  }

  const ReceiveMinimumBillingError = (error) => {
    if (error) {
      setMinimumBillingError(
        "El total de mensajes SMS a enviar no alcanza el monto mínimo establecido de " +
          rates.MinimumBilling +
          " USD por campaña "
      )
    } else {
      setMinimumBillingError("")
    }
  }

  React.useEffect(() => {
    setCalculatorData({
      ...calculatorData,
      prices: rates,
    })
  }, [rates])

  return (
    <React.Fragment>
      <div style={{ padding: "31px 0 0 0" }}>
        <HeadSectionComponent
          title={"Tarifas"}
          notification={notification}
          subTitle={
            "<p> <b>Visualiza y edita</b> el monto de tus tarifas por operadora.</p>"
          }
          tooltipTitle={"Añadir"}
          showAddButton={false}
          onAddButtonClick={() => {}}
        />

        <div className={classes.selectContainer}>
          <Select
            id="business-input"
            sx={{ width: "219px" }}
            className={classes.select}
            value={business}
            IconComponent={ExpandMoreIcon}
            onChange={(e) => {
              setBusiness(e.target.value)
            }}
          >
            <MenuItem
              key={0}
              value={0}
              sx={{
                color: "black",
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "Open Sans !important",
              }}
            >
              Empresas
            </MenuItem>
            {businessRates.map((br) => (
              <MenuItem
                key={br.business.id}
                value={br.business.id}
                sx={{
                  color: "black",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontFamily: "Open Sans !important",
                }}
              >
                {br.business.razonSocial}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className={classes.divContainer} id="ratesContainer">
          <div
            style={{
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "409px",
              height: "fit-content",
              backgroundColor: "white",
              border: "1px solid black",
              padding: "27px 35px 19px 40px",
              margin: "0 !important",
            }}
          >
            <CardContent
              sx={{ padding: "0 !important", margin: "0 !important" }}
            >
              <CalculatorInput
                sendCalculatorData={ReceiveCalculatorData}
                minimumBillingError={minimumBillingError}
                rates={rates}
              />
            </CardContent>
          </div>

          <CalculatorOutput
            calculatorData={calculatorData}
            sendMinimumBillingError={ReceiveMinimumBillingError}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RatesCalculator
