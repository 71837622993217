import { makeStyles } from "@mui/styles"
import * as React from "react"
import FormControl from "@mui/material/FormControl"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import "./index.css"

const useStyles = makeStyles(() => ({
  inputText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "black",
    margin: "0",
    padding: "0.3em 0",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "calc(100% - 10px)",
    padding: "0",
    margin: "0",
    height: "16px",
  },
  errorText: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    color: "#d94141",
    margin: "0",
    padding: "0",
  },
  field: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "none",
    textAlign: "center",
    height: "58px",
    width: "120px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 0 0 40px",
    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      border: "2px solid grey",
      borderTopRightRadius: "none",
      borderBottomRightRadius: "none",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      borderRight: "none",
    },
  },
  errorField: {
    border: "2px solid #d94141",
    backgroundColor: "white",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "none",
    textAlign: "center",
    height: "58px",
    width: "120px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 0 0 40px",
    color: "red",
    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      border: "2px solid grey",
      borderTopRightRadius: "none",
      borderBottomRightRadius: "none",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      borderRight: "none",
      color: "grey",
    },
  },
  field2: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    textAlign: "center",
    height: "58px",
    width: "150px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 0 0 0",
    "&:focus": {
      outline: "none",
    },
  },
  errorField2: {
    border: "2px solid #d94141",
    backgroundColor: "white",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    textAlign: "center",
    height: "58px",
    width: "150px",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0",
    color: "red",
    "&:focus": {
      outline: "none",
    },
  },
  inputLabelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "150px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    width: "330px",
  },
  inputButtons: {
    display: "flex",
    flexDirection: "column",
    height: "58px",
    width: "30px",
  },
  inputButton1: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderBottom: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    cursor: "pointer",
  },
  inputButton2: {
    backgroundColor: "white",
    border: "2px solid black",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderTop: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },
  inputButton1Error: {
    backgroundColor: "white",
    border: "2px solid #d94141",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderBottom: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    cursor: "pointer",
  },
  inputButton2Error: {
    backgroundColor: "white",
    border: "2px solid #d94141",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderTop: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },
  inputButton1Disabled: {
    backgroundColor: "white",
    border: "2px solid grey",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderBottom: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    cursor: "pointer",
  },
  inputButton2Disabled: {
    backgroundColor: "white",
    border: "2px solid grey",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "none",
    borderBottomLeftRadius: "none",
    borderLeft: "none",
    borderTop: "none",
    height: "100%",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },
  hidden: {
    display: "none",
  },
  errorContainerGlobal: {
    display: "flex",
    alignItems: "center",
    width: "309px",
    margin: "18px 0 0 0",
    height: "43px",
  },
  errorText2: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    color: "#FF0606",
    margin: "0",
    padding: "0",
  },
}))

const CalculatorInput = ({
  sendCalculatorData,
  minimumBillingError,
  rates,
}) => {
  const [msgQuantity, setMsgQuantity] = React.useState({
    Digitel: "0",
    Movistar: "0",
    Movilnet: "0",
    Total: "0",
  })
  const [msgQuantityError, setMsgQuantityError] = React.useState({
    Digitel: "",
    Movistar: "",
    Movilnet: "",
    Total: "",
  })

  const [disabled, setDisabled] = React.useState({
    Digitel: false,
    Movistar: false,
    Movilnet: false,
  })

  const changeMsgQuantityHandler = (event) => {
    if (
      isNaN(parseInt(event.target.value.replaceAll(".", ""))) &&
      event.target.value !== ""
    ) {
      return
    } else {
      setMsgQuantity({
        ...msgQuantity,
        [event.target.id]:
          event.target.value !== ""
            ? parseInt(event.target.value.replaceAll(".", "")).toLocaleString(
                "de-DE"
              )
            : "",
      })
    }
  }

  React.useEffect(() => {
    let error = false

    if (
      parseInt(
        msgQuantity.Digitel != ""
          ? msgQuantity.Digitel.replaceAll(".", "")
          : "0"
      ) < 0
    ) {
      setMsgQuantityError({
        ...msgQuantityError,
        Digitel: "Mínimo 0",
      })
      error = true
    }

    if (
      parseInt(
        msgQuantity.Movistar != ""
          ? msgQuantity.Movistar.replaceAll(".", "")
          : "0"
      ) < 0
    ) {
      setMsgQuantityError({
        ...msgQuantityError,
        Movistar: "Mínimo 0",
      })
      error = true
    }

    if (
      parseInt(
        msgQuantity.Movilnet != ""
          ? msgQuantity.Movilnet.replaceAll(".", "")
          : "0"
      ) < 0
    ) {
      setMsgQuantityError({
        ...msgQuantityError,
        Movilnet: "Mínimo 0",
      })
      error = true
    }

    if (
      parseInt(
        msgQuantity.Movistar != ""
          ? msgQuantity.Movistar.replaceAll(".", "")
          : "0"
      ) +
        parseInt(
          msgQuantity.Movilnet != ""
            ? msgQuantity.Movilnet.replaceAll(".", "")
            : "0"
        ) +
        parseInt(
          msgQuantity.Digitel != ""
            ? msgQuantity.Digitel.replaceAll(".", "")
            : "0"
        ) >
      5000000
    ) {
      setMsgQuantityError({
        Digitel: " ",
        Movistar: " ",
        Movilnet: " ",
        Total: "Máximo 5.000.000",
      })
      error = true
    }
    setMsgQuantity({
      ...msgQuantity,
      Total: (
        parseInt(
          msgQuantity.Digitel != ""
            ? msgQuantity.Digitel.replaceAll(".", "")
            : "0"
        ) +
        parseInt(
          msgQuantity.Movistar != ""
            ? msgQuantity.Movistar.replaceAll(".", "")
            : "0"
        ) +
        parseInt(
          msgQuantity.Movilnet != ""
            ? msgQuantity.Movilnet.replaceAll(".", "")
            : "0"
        )
      ).toLocaleString("de-DE"),
    })
    if (!error) {
      sendCalculatorData({
        msgQuantity: {
          Digitel: msgQuantity.Digitel != "" ? msgQuantity.Digitel : "0",
          Movistar: msgQuantity.Movistar != "" ? msgQuantity.Movistar : "0",
          Movilnet: msgQuantity.Movilnet != "" ? msgQuantity.Movilnet : "0",
          Total: msgQuantity.Total != "" ? msgQuantity.Total : "0",
        },
      })
      if (minimumBillingError == "")
        setMsgQuantityError({
          Digitel: "",
          Movistar: "",
          Movilnet: "",
          Total: "",
        })
    }
  }, [msgQuantity.Digitel, msgQuantity.Movistar, msgQuantity.Movilnet])

  React.useEffect(() => {
    if (minimumBillingError != "")
      setMsgQuantityError({
        Digitel: " ",
        Movistar: " ",
        Movilnet: " ",
        Total: "",
      })
    else {
      setMsgQuantityError({
        Digitel: "",
        Movistar: "",
        Movilnet: "",
        Total: "",
      })
    }
  }, [minimumBillingError])

  React.useEffect(() => {
    if (rates.Digitel == 0 && rates.Movistar == 0 && rates.Movilnet == 0) return
    let msgQuantityAux = msgQuantity
    let disabledAux = disabled
    if (rates.Digitel == 0) {
      msgQuantityAux.Digitel = "0"
      disabledAux.Digitel = true
    } else {
      disabledAux.Digitel = false
    }

    if (rates.Movistar == 0) {
      msgQuantityAux.Movistar = "0"
      disabledAux.Movistar = true
    } else {
      disabledAux.Movistar = false
    }

    if (rates.Movilnet == 0) {
      msgQuantityAux.Movilnet = "0"
      disabledAux.Movilnet = true
    } else {
      disabledAux.Movilnet = false
    }
    setMsgQuantity(msgQuantityAux)
    setDisabled(disabledAux)
  }, [rates])

  const classes = useStyles()

  return (
    <FormControl
      sx={{
        width: "calc(100% - 80px)",
        display: "flex",
        flexDirection: "column",
        padding: "0 !important",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontfamily: "Open Sans !important",
          fontStyle: "normal !important",
          fontWeight: "700 !important",
          fontSize: "25px !important",
          lineHeight: "normal !important",
          letterSpacing: "0.15px !important",
          color: "black !important",
          marginBottom: "37px",
        }}
      >
        Calcula tu próxima campaña
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontfamily: "Open Sans !important",
          fontStyle: "normal !important",
          lineHeight: "normal !important",
          fontWeight: "400 !important",
          fontSize: "18px !important",
          letterSpacing: "0.15px !important",
          color: "#363636 !important",
          marginBottom: "29px",
          width: "270px",
        }}
      >
        Cantidad de Mensajes a Cotizar
      </Typography>

      <div className={classes.inputGroup}>
        <div className={classes.inputLabelContainer}>
          <div style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Digitel 412 / 422
            </Typography>
          </div>
          <div className={classes.inputContainer}>
            <input
              disabled={disabled.Digitel}
              value={msgQuantity.Digitel}
              id="Digitel"
              onChange={changeMsgQuantityHandler}
              className={`${
                msgQuantityError.Digitel == ""
                  ? classes.field
                  : classes.errorField
              } `}
            />
            <div className={classes.inputButtons}>
              <div
                onClick={() => {
                  if (!disabled.Digitel)
                    setMsgQuantity({
                      ...msgQuantity,
                      Digitel: (
                        parseInt(
                          msgQuantity.Digitel != ""
                            ? msgQuantity.Digitel.replaceAll(".", "")
                            : "0"
                        ) + 1
                      ).toLocaleString("de-DE"),
                    })
                }}
                className={
                  disabled.Digitel
                    ? classes.inputButton1Disabled
                    : msgQuantityError.Digitel == ""
                    ? classes.inputButton1
                    : classes.inputButton1Error
                }
              >
                <ArrowDropUpIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    top: "7px",
                  }}
                />
              </div>
              <div
                onClick={() => {
                  if (!disabled.Digitel)
                    if (
                      parseInt(
                        msgQuantity.Digitel != ""
                          ? msgQuantity.Digitel.replaceAll(".", "")
                          : "0"
                      ) > 0
                    ) {
                      setMsgQuantity({
                        ...msgQuantity,
                        Digitel: (
                          parseInt(
                            msgQuantity.Digitel != ""
                              ? msgQuantity.Digitel.replaceAll(".", "")
                              : "0"
                          ) - 1
                        ).toLocaleString("de-DE"),
                      })
                    }
                }}
                className={
                  disabled.Digitel
                    ? classes.inputButton2Disabled
                    : msgQuantityError.Digitel == ""
                    ? classes.inputButton2
                    : classes.inputButton2Error
                }
              >
                <ArrowDropDownIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    bottom: "7px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.errorContainer}>
          <p className={classes.errorText}>{msgQuantityError.Digitel}</p>
        </div>

        <div className={classes.inputLabelContainer}>
          <div style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Movistar 414 / 424
            </Typography>
          </div>
          <div className={classes.inputContainer}>
            <input
              disabled={disabled.Movistar}
              value={msgQuantity.Movistar}
              id="Movistar"
              onChange={changeMsgQuantityHandler}
              className={`${
                msgQuantityError.Movistar == ""
                  ? classes.field
                  : classes.errorField
              } `}
            />
            <div className={classes.inputButtons}>
              <div
                onClick={() => {
                  if (!disabled.Movistar)
                    setMsgQuantity({
                      ...msgQuantity,
                      Movistar: (
                        parseInt(
                          msgQuantity.Movistar != ""
                            ? msgQuantity.Movistar.replaceAll(".", "")
                            : "0"
                        ) + 1
                      ).toLocaleString("de-DE"),
                    })
                }}
                className={
                  disabled.Movistar
                    ? classes.inputButton1Disabled
                    : msgQuantityError.Movistar == ""
                    ? classes.inputButton1
                    : classes.inputButton1Error
                }
              >
                <ArrowDropUpIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    top: "7px",
                  }}
                />
              </div>
              <div
                onClick={() => {
                  if (!disabled.Movistar)
                    if (
                      parseInt(
                        msgQuantity.Movistar != ""
                          ? msgQuantity.Movistar.replaceAll(".", "")
                          : "0"
                      ) > 0
                    ) {
                      setMsgQuantity({
                        ...msgQuantity,
                        Movistar: (
                          parseInt(
                            msgQuantity.Movistar != ""
                              ? msgQuantity.Movistar.replaceAll(".", "")
                              : "0"
                          ) - 1
                        ).toLocaleString("de-DE"),
                      })
                    }
                }}
                className={
                  disabled.Movistar
                    ? classes.inputButton2Disabled
                    : msgQuantityError.Movistar == ""
                    ? classes.inputButton2
                    : classes.inputButton2Error
                }
              >
                <ArrowDropDownIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    bottom: "7px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.errorContainer}>
          <p className={classes.errorText}>{msgQuantityError.Movistar}</p>
        </div>

        <div className={classes.inputLabelContainer}>
          <div style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Movilnet 416 / 426
            </Typography>
          </div>
          <div className={classes.inputContainer}>
            <input
              disabled={disabled.Movilnet}
              value={msgQuantity.Movilnet}
              id="Movilnet"
              onChange={changeMsgQuantityHandler}
              className={`${
                msgQuantityError.Movilnet == ""
                  ? classes.field
                  : classes.errorField
              } `}
            />
            <div className={classes.inputButtons}>
              <div
                onClick={() => {
                  if (!disabled.Movilnet)
                    setMsgQuantity({
                      ...msgQuantity,
                      Movilnet: (
                        parseInt(
                          msgQuantity.Movilnet != ""
                            ? msgQuantity.Movilnet.replaceAll(".", "")
                            : "0"
                        ) + 1
                      ).toLocaleString("de-DE"),
                    })
                }}
                className={
                  disabled.Movilnet
                    ? classes.inputButton1Disabled
                    : msgQuantityError.Movilnet == ""
                    ? classes.inputButton1
                    : classes.inputButton1Error
                }
              >
                <ArrowDropUpIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    top: "7px",
                  }}
                />
              </div>
              <div
                onClick={() => {
                  if (!disabled.Movilnet)
                    if (
                      parseInt(
                        msgQuantity.Movilnet != ""
                          ? msgQuantity.Movilnet.replaceAll(".", "")
                          : "0"
                      ) > 0
                    ) {
                      setMsgQuantity({
                        ...msgQuantity,
                        Movilnet: (
                          parseInt(
                            msgQuantity.Movilnet != ""
                              ? msgQuantity.Movilnet.replaceAll(".", "")
                              : "0"
                          ) - 1
                        ).toLocaleString("de-DE"),
                      })
                    }
                }}
                className={
                  disabled.Movilnet
                    ? classes.inputButton2Disabled
                    : msgQuantityError.Movilnet == ""
                    ? classes.inputButton2
                    : classes.inputButton2Error
                }
              >
                <ArrowDropDownIcon
                  sx={{
                    margin: "0",
                    padding: "0",
                    fontSize: "25px",
                    position: "relative",
                    bottom: "7px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.errorContainer}>
          <p className={classes.errorText}>{msgQuantityError.Movilnet}</p>
        </div>

        <div className={classes.inputLabelContainer}>
          <div style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "650",
                fontSize: "15px",
                letterSpacing: "0.15px",
                color: "black",
              }}
            >
              Total de Mensajes
            </Typography>
          </div>
          <div className={classes.inputContainer}>
            <input
              value={msgQuantity.Total}
              id="Total"
              disabled
              className={`${
                msgQuantityError.Total == ""
                  ? classes.field2
                  : classes.errorField2
              } `}
            />
          </div>
        </div>
        <div
          className={`${
            msgQuantityError.Total == ""
              ? minimumBillingError != ""
                ? classes.errorContainerGlobal
                : classes.hidden
              : classes.errorContainerGlobal
          }`}
        >
          <p className={classes.errorText2}>
            {msgQuantityError.Total == ""
              ? minimumBillingError
              : msgQuantityError.Total}
          </p>
        </div>
      </div>
    </FormControl>
  )
}

CalculatorInput.propTypes = {
  sendCalculatorData: PropTypes.func.isRequired,
  minimumBillingError: PropTypes.string.isRequired,
  rates: PropTypes.object.isRequired,
}

export default CalculatorInput
