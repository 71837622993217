import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    container:{
      "& .MuiPaper-root":{
       width:'fit-content',
       padding:"20px 20px 20px 20px",
      },
    },
    titleContainer: {
      display: "flex !important",
      justifyContent: "start !important",
      alignItems: "start !important",
      marginBottom: "15px !important",
    },
    titleText: {
      fontfamily: "Open Sans !important",
      fontWeight: "700 !important",
      fontSize: "20px !important",
      color: "#4E5589",

    },
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    
  })
);

const LoadingDialog = ({ shouldOpen }) => {
  const classes = useStyles();



  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      className={classes.container}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ color: '#4E5589' }} className={classes.progressContainer}>
        <CircularProgress size="5em" color="inherit"/>
      </DialogContent>
      
    </Dialog>
  );
};

LoadingDialog.propTypes = {
  shouldOpen: PropTypes.bool,
};

export default LoadingDialog;
