import React, {useState, useEffect} from "react";
import { Routes } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router";
import { useApp } from "../../AppProvider";
import { styled } from "@mui/material/styles";
import { Grid, Box, Toolbar, List, Chip, CssBaseline, IconButton, ListItemButton, ListItemText, ListItemIcon, Typography, Avatar, Popper, Fade, Paper, Button, Divider  } from "@mui/material";
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import LogoutIcon from "@mui/icons-material/Logout";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import sidebarIcon from "../../assets/esms-logo-blanco.svg";
import { ROLES } from './constants';
import { ROUTES } from "../../routers/constants";
import MenuIcon from '@mui/icons-material/Menu';
import { ClickAwayListener } from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    sectionButton:{
      fontFamily: "Open Sans",
      fontWeight: "normal",
      display:'block'
    },
    sectionButtonIcon:{
      color:'red !important',
      '&.MuiListItemIcon-root':{
        paddingLeft: '2px  !important'
        },
    },
    sectionButtonText:{
  
      fontStyle: "normal",
      fontSize: "20px !important",
      fontWeight:'400 !important',
      lineHeight: "24px",
      letterSpacing: "0.15px",
      "@media screen and (max-width: 1350px)": {
        fontSize: "16px !important",
      },
    },
    sectionList:{
      color:'rgba(108, 117, 128, 0.4) !important',
      '& span.MuiTypography-root':{
        fontFamily: "Open Sans !important",
        fontWeight: "normal !important",
      },
      
      '& div.Mui-selected':{ 
        backgroundColor:'rgba(217, 217, 217, 0.3) !important',
       
        color:'rgba(78, 85, 137, 1) !important',
        borderRadius: 20,
        '& div.MuiListItemIcon-root':{
          color:'rgba(78, 85, 137, 1) !important',
        },
        '& span.MuiTypography-root':{
          fontFamily: "Open Sans !important",
          color:'rgba(78, 85, 137, 1) !important',
          fontWeight: "bold !important",
        }
      },
      '& div.MuiListItemIcon-root':{
        color:'rgba(108, 117, 128, 0.4) !important',
        minWidth:35,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        /* height:30 */
        
      },
      '& div.MuiButtonBase-root:hover':{
        borderRadius: 20,
        
      },
      '& div.Mui-disabled':{
        
        opacity:1
        
      },
      '& div.MuiButtonBase-root':{
        margin: "15px 10px",
        padding: '4px 10px',
        "@media screen and (max-width: 1350px)": {
          margin: "15px 5px",
        },
        
      },
    },
    sectionListHidden:{
      color:'rgba(108, 117, 128, 0.4) !important',
      
      '& div.Mui-selected':{ 
        backgroundColor:'rgba(217, 217, 217, 0.3) !important',
       
        color:'rgba(78, 85, 137, 1) !important',
        
        fontFamily: "Open Sans",
        fontWeight: "700",
        ' & p.sectionListHiddenLabel':{
          color:'red !important',
        },
        '& div.MuiListItemIcon-root':{
          color:'rgba(78, 85, 137, 1) !important',
        }
      },
      '& div.MuiListItemIcon-root':{
        color:'rgba(108, 117, 128, 0.4) !important',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        minWidth:35,
        fontSize:40
        
      },
      '& div.MuiButtonBase-root:hover':{
        borderRadius: 15,
        
      },
      '& div.Mui-disabled':{
        opacity:1
      },
      '& div.MuiButtonBase-root':{
        margin: "auto",
        height:'45px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'40px',
        borderRadius:'15px'

      },
    },
    sectionListHiddenLabel: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color:'#000000',
        fontSize:'9px !important',  
        fontFamily: "Open Sans !important",
        fontWeight:'300 !important',
    },
    sectionListHiddenLabelSelected: {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      color:'#4E5589',
      fontSize:'9px !important',  
      fontFamily: "Open Sans !important",
      fontWeight:'700 !important',
  },
    ticketIcon: {

  '&.MuiListItemIcon-root':{
    paddingLeft: '12px  !important'
    },
  },
    businessIcon: {

    '&.MuiListItemIcon-root':{
      paddingLeft: '8px  !important'
      },
    },
  
    esmsIcon : {
        width:'25%',
        maxWidth:120,
        minWidth:140,
        marginLeft:'20px',
        "@media screen and (max-width: 1350px)": {
          
        }

    },
    soonButton: {
      backgroundColor:'rgba(61, 183, 110, 1) !important',
      color:'white !important',
      marginLeft:'10px !important',
      "@media screen and (max-width: 1350px)": {
        marginLeft:'0px !important',
        fontSize:'11px !important'
      },
    },
    iconAvatar:{
      backgroundColor: "rgba(61, 183, 110, 1) !important", 
      textTransform: "capitalize !important" , 
      marginRight:'10px !important', 
      width:'40px !important',
      height:'40px !important'
    },
    logoutName:{
      fontStyle: "normal !important",
      fontWeight: "bold !important",
      fontSize: "20px !important",
      lineHeight: "24px !important",
      letterSpacing: "0.15px !important",
      textTransform: "capitalize !important",
      fontFamily:'Open Sans !important',
      
    },
    logoutRole:{
      fontStyle: "normal !important",
      fontWeight: "normal !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      letterSpacing: "0.15px !important",
      fontFamily:'Open Sans !important',
      textTransform:"capitalize !important",
      marginTop:'3px !important'
     
    },
    buttonContainer:{
      paddingTop:'15px'

    }
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "@media screen and (max-width: 1350px)": {
    width: 220,
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height:"9.57%",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'rgba(78, 85, 137, 1)',
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const RoleFormat = (role) => {
  switch(role){
    case ROLES.SUPER:
      return "Super admin";
    case ROLES.ADMIN:
      return "Admin";
    case ROLES.CLIENT:
      return "Contacto";
    case ROLES.EXT:
      return "Externo"
  }
}

const MiniDrawer = ({ user, SECTIONS}) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [open, setOpen] = useState(true);
  const { currentUser, clearCurrentUser, showDrawer, handleShowDrawer, setSearchOptions} = useApp();
  const [openOptions,setOpenOptions] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowEl, setArrowEl] = useState(null);


  if (user === null || user === undefined) {
    navigate("/");
  }

  const handleDrawerOpen = (state) => {
    setOpen(state);
  };

  const handleUserOptions = (state) => {
    setOpenOptions(state);
  };

  const handleLogout = () => {
    clearCurrentUser();
    handleShowDrawer(true);
    navigate("/");
  };
  

  return (
    <Box style={{ display: "flex" }}>
      <CssBaseline />
      <AppBar

        open={open}
        style={{ height: "7vh", minHeight:90 , maxHeight:100}}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar style={{ height: "100%", padding:'0px', /*backgroundColor:'rgba(78, 85, 137, 1)'*/ }}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item container
            xs={4}
            sm={4}
            md={4}>
               <IconButton
                  onClick={() => {
                    handleDrawerOpen(!open);
                    handleUserOptions(false)
                  }}
                  style={{ color: "white", padding:'0px', marginLeft:'15px' }}
                  >
                  <MenuIcon sx={{fontSize:'45px'}}/> 
                </IconButton>
            <img
              alt="sidebar-icon"
              src={sidebarIcon}
              className={classes.esmsIcon}
            />
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              wrap="nowrap"
              spacing={1}
              xs={8}
              sm={8}
              md={8}
            > 
          <ClickAwayListener onClickAway={()=> {handleUserOptions(false)}}>
            <Button sx={{color:'white', marginRight:5, marginTop:1}}   onClick={(event)=> {
              setAnchorEl(event.currentTarget);
              handleUserOptions(!openOptions);
            }} >
              <Grid item>
                <Avatar className={classes.iconAvatar}>
                  <Typography sx={{fontWeight:'bold', fontSize:'20px'}}>{currentUser.firstName[0]}</Typography>
                </Avatar>
              </Grid>
              <Grid item container direction="column" sx={{textAlign:'initial', marginRight:1}} >
                  <Grid item>
                  <Typography
                  className={classes.logoutName}
                  >
                  {`${currentUser.firstName} ${currentUser.lastName}`}
                  </Typography>
                  </Grid>
                <Grid item> 
                <Typography
                  className={classes.logoutRole}

                >
                  {RoleFormat(currentUser.role)}
                </Typography>
                </Grid>
              </Grid>
              
                <Grid item sx={{top:-8,position:'relative'}}>
                {!openOptions ?<ExpandMore /> :  <ExpandLess /> }
                </Grid>
                
                </Button>
              </ClickAwayListener>
            </Grid>
            
          </Grid>
        </Toolbar>
      </AppBar>
  
              <Popper
                  style={{ zIndex: 10000, paddingTop: "14px"  }}
                  open={openOptions}
                  anchorEl={anchorEl} 
                  placement={"bottom-end"}
                  transition
                  modifiers={[
                    {
                      name: "arrow",
                      enabled: true,
                      options: {
                        element: arrowEl,
                      },
                    },
                  ]}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ backgroundColor:'white', cursor:'pointer', boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius:2}}    
                      onClick={() => {
                                handleLogout();
                              }}>
                        <Grid
                          container
                          direction="row"
                          padding={"5px 45px 5px 20px "}
                          
                        >
                          <Grid item sx={{marginRight:'8px', display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
                              <LogoutIcon  sx={{ color:'rgba(217, 217, 217, 1)', fontSize:'20px' }}/>
                          
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                fontFamily: "Open Sans !important",
                                color:'#4E5589'
                              }}
                            >
                              Cerrar sesión
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
            

      {showDrawer && <Drawer variant="permanent" open={open}>
        <DrawerHeader  style={{ height: "7vh", minHeight:90, maxHeight:100}}/>
        <List className={open ? classes.sectionList : classes.sectionListHidden}>
          {SECTIONS.filter((section) => section.roles.includes(currentUser.role))
            .map(({name,icon,link, shortLabel},ind) => <Grid key={`${currentUser.role}-section-${ind}`} className={!open ? classes.buttonContainer: ''}> <ListItemButton  
            className={ classes.sectionButton}
            disabled={link === "campaigns" && currentUser.role === ROLES.EXT}
            onClick={() => {
              navigate(link);
              setSearchOptions(null);
              handleUserOptions(false);
            }}
            selected={link === currentLocation.pathname.split('/')[1]}
            id={ind}
          >
            <ListItemIcon className={ shortLabel == 'Ticket' ? classes.ticketIcon :  shortLabel == 'Empresa' ?  classes.businessIcon : classes.sectionButtonIcon}>
              {icon}
            </ListItemIcon>      
            {open ? 
              <ListItemText
                    primary={
                      (link === "campaigns" && currentUser.role === ROLES.EXT )? 
                      <Typography>  
                        {name} <Chip size="small" className={classes.soonButton} label="¡Muy pronto!"/>
                      </Typography>:
                  name}
                    primaryTypographyProps={{ className:classes.sectionButtonText  }}  
                  />
            : <React.Fragment/>
            }
          </ListItemButton> 
          {!open  ?    <Typography className={ link === currentLocation.pathname.split('/')[1] ? classes.sectionListHiddenLabelSelected: classes.sectionListHiddenLabel}>  
                        {shortLabel}
                      </Typography> : <React.Fragment/> }
          
          </Grid> )}
        </List>
      </Drawer>}
      <Box component="main" sx={{ flexGrow: 1, minHeight:'100vh', padding:currentLocation.pathname.split('/')[1] === 'home' ? '90px 0px 0px 0px' : '120px 30px 30px 30px'}}>
        <Routes>{ROUTES[currentUser.role]}</Routes>
      </Box>
    </Box>
  );
};

MiniDrawer.propTypes = {
  user: PropTypes.object.isRequired,
  SECTIONS: PropTypes.array.isRequired,
};

export default MiniDrawer;
