import { post, get, put, patch, axiosDelete } from "../../../helpers/axios"
import { RateTypes } from "../constants/constants"

const BASE_PATH = "/rates"

const BASE_PATH_EMP = "/business"

const BASE_PATH_SERVICE_PROVIDER = "/service-provider"

export const getServiceProvidersBusiness = (id) =>
  get(`${BASE_PATH_SERVICE_PROVIDER}/business/${id}`)

//# API DE SERVICIOS PARA MODULO TARIFAS

// Crea una tarifa

export const createRate = (
  movistar,
  digitel,
  movilnet,
  vigency,
  minimumBilling,
  createRateBusinesses
) =>
  post(`${BASE_PATH}`, {
    createRateBusinesses,
    createRate: { movistar, digitel, movilnet, vigency, minimumBilling },
  })

//Consulta una tarifa por id
export const getRate = (id) => get(`${BASE_PATH}/${id}`)

//Consulta una tarifa por correo
export const getEmailRate = (email) => get(`${BASE_PATH}/${email}`)

//Consulta para obtener todos las tarifas
//export const getAllRates = () => get(`${BASE_PATH}`);
export const getAllRates = () => {
  return get(`${BASE_PATH}`)
}

export const getBusinessesWithoutRates = () => {
  return get(`${BASE_PATH}/business-without-rates`)
}


//Actualiza datos de una tarifa al editarlas sin modificar el correo
export const updateRate = (id, updateBody) =>
  patch(`${BASE_PATH}/${id}`, updateBody)

//Actualiza datos de una tarifa si modfica campo correo en la edicion.
export const updateRateEmail = (id, updateEmail) =>
  patch(`${BASE_PATH}/update-email/${id}`, updateEmail)

//Consulta para obtener todas las empresas
export const getAllBusiness = () => get(`${BASE_PATH_EMP}`)

//Desactivar tarifa por id
export const disableRate = (id) => axiosDelete(`${BASE_PATH}/remove/${id}`)

//Activar tarifa por id
export const enableRate = (id) => patch(`${BASE_PATH}/restore/${id}`)

//Reenviar link por tarifa por id
export const resendEmail = (id) => patch(`${BASE_PATH}/resend-email/${id}`)
