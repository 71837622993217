import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useEffect, useState, useCallback } from "react"
import { getAllRates, getBusinessesWithoutRates } from "../../services/api"
import ViewRateModal from "../viewRateModal"
import Popper from "@mui/material/Popper"
import Fade from "@mui/material/Fade"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { useApp } from "../../../../AppProvider"
import * as React from "react"
import HeadSectionComponent from "../../../../components/SectionTable/HeadSection"
import TableComponent from "../../../../components/SectionTable/Table"
import NewRateModal from "../newRateModal/newRateModal"

const headCells = [
  {
    id: "BusinessLabel",
    label: "Empresas",
    numeric: false,
  },
  {
    id: "MovistarLabel",
    label: "Movistar",
    numeric: false,
  },
  {
    id: "MovilnetLabel",
    label: "Movilnet",
    numeric: false,
  },
  {
    id: "DigitelLabel",
    label: "Digitel",
    numeric: false,
  },
]

const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#FFFFFF",
    "&, & .MuiListItemIcon-root": {
      color: "#000000",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "rgba(9, 87, 160, 0.15)",
    "&, & .MuiListItemIcon-root": {
      color: "#0154A1",
    },
  },
})

const ITEM_HEIGHT = 48

const TableRatesData = () => {
  const [lista, setLista] = useState([])

  const [showUpdateRateModal, setShowUpdateRateModal] = useState(false)
  const [showNewRateModal, setShowNewRateModal] = useState(false)
  const [selectedRate, setSelectedRate] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)

  const [openMenu, setOpenMenu] = useState(false)

  const [updateModal, setUpdateModal] = useState(false)

  const { showNotification } = useApp()

  const [loading, setLoading] = useState(true)

  const [confirmModalConfig, setConfirmModalConfig] = useState({
    type: "",
    title: "",
    submitAction: async () => ({}),
    message: "",
    notification: {
      success: "",
      error: "",
    },
  })

  const [filteredRatesList, setFilteredRatesList] = useState(lista)
  /////////////////////////////////// VARIABLES Y FUNCIOES (PAGINADO - ORDENADO BUSQUEDA)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("BusinessLabel")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchInput, setSearchInput] = useState("")
  const [notification, setNotification] = useState(false)

  const GetBusinessesWithoutRates = async (rates) => {
    const businesses = await getBusinessesWithoutRates()
    let auxList = rates
    if (businesses.data.length === 0) {
      setNotification(false)
      return rates
    }
    setNotification(true)
    for (const business of businesses.data) {
      auxList = auxList.map((rate) => {
        if (rate.business.id === business.id) {
          return {
            ...rate,
            BusinessLabel: "! " + rate.business.razonSocial,
          }
        }
        return rate
      })
    }
    return auxList
  }

  const fetchRates = useCallback(async () => {
    try {
      const allRates = await getAllRates()
      const rates = allRates.data.map((rate) => {
        return {
          ...rate,
          BusinessLabel: rate.business.razonSocial,
          MovilnetLabel: rate.MovilnetRate.toLocaleString("de-DE", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          }),
          DigitelLabel: rate.DigitelRate.toLocaleString("de-DE", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          }),
          MovistarLabel: rate.MovistarRate.toLocaleString("de-DE", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          }),
        }
      })
      const aux = await GetBusinessesWithoutRates(rates)
      setLista(aux)
      setLoading(false)
    } catch (e) {
      console.log(e)
      showNotification(
        "Lo sentimos, se ha producido un error inesperado al obtener el listado de Tarifas",
        "error"
      )
    }
  }, [])

  const reloadResources = () => {
    fetchRates()
  }

  const applyFilter = (data, filterValue) => {
    let filteredList = []
    if (filterValue === "") {
      filteredList = data
    } else if (filterValue !== "") {
      filteredList = data.filter((rate) => {
        const alteredValue = filterValue.toLowerCase()
        return (
          rate.DigitelLabel.toLowerCase().includes(alteredValue) ||
          rate.MovilnetLabel.toLowerCase().includes(alteredValue) ||
          rate.MovistarLabel.toLowerCase().includes(alteredValue) ||
          rate.business.razonSocial.toLowerCase().includes(alteredValue)
        )
      })
    }

    return filteredList
  }

  useEffect(() => {
    fetchRates()
  }, [fetchRates])

  useEffect(() => {
    setFilteredRatesList(applyFilter(lista, searchInput))
  }, [lista, searchInput])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setSelectedRate({})
    setAnchorEl(null)
    setOpenMenu(false)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchInputChange = (event) => {
    setSearchInput(event.target.value)
    setAnchorEl(null)
    setOpenMenu(false)
    setPage(0)
  }

  const modifyCallback = () => {
    procesoExitoso()
  }

  const menuClick = (rate) => (event) => {
    if (selectedRate != rate) {
      setOpenMenu(false)
    }
    setSelectedRate({})
    setSelectedRate(rate)
    setAnchorEl(event.currentTarget)
    setOpenMenu((previousOpen) => !previousOpen)
  }

  const onUpdateRatesClick = () => {
    setUpdateModal(true)
    setShowUpdateRateModal(true)
  }

  const onViewRatesClick = () => {
    setUpdateModal(false)
    setShowUpdateRateModal(true)
  }

  const columOrderToShow = [
    {
      name: "BusinessLabel",
      length: false,
      link: false,
      notification: true,
    },
    {
      name: "MovistarLabel",
      length: false,
      link: false,
    },
    {
      name: "MovilnetLabel",
      length: false,
      link: false,
    },
    {
      name: "DigitelLabel",
      length: false,
      link: false,
    },
  ]

  const onNewRateClick = () => {
    setSelectedRate({})
    setShowNewRateModal(true)
  }

  const procesoExitoso = async () => {
    setOpenMenu(false)
    reloadResources()
  }

  return (
    <React.Fragment>
      <HeadSectionComponent
        title={"Tarifas"}
        notification={notification}
        subTitle={
          "<p> <b>Visualiza y edita</b> el monto de tus tarifas por operadora.</p>"
        }
        tooltipTitle={"Añadir"}
        showAddButton={true}
        onAddButtonClick={onNewRateClick}
      />
      <TableComponent
        showSearchInput={true}
        searchInputConfig={{
          searchInputValue: searchInput,
          onChangeSearchInput: searchInputChange,
          onClearSearchInput: modifyCallback,
          searchInputPlaceHolder: "Buscar",
        }}
        tableHeaderProps={{
          order: order,
          orderBy: orderBy,
          onRequestSort: handleRequestSort,
          headCells: headCells,
          showActionCell: true,
        }}
        filteredData={filteredRatesList}
        noFoundDataLabel={"Lo sentimos, no hay resultados para tu búsquedas."}
        isLoading={loading}
        orderOfColumnsToDisplay={columOrderToShow}
        showMenuColum={true}
        menuColumConfig={{
          onOpenMenu: menuClick,
        }}
        paginationConfig={{
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
          page: page,
          rowsPerPage: rowsPerPage,
          rowsPerPageLabel: "Registro por páginas:",
          rowsPerPageSequence: [5, 10, 15],
        }}
      />
      <Popper
        id="popper"
        open={openMenu}
        anchorEl={anchorEl}
        placement="left-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List
                sx={{ width: 113, height: 61, paddingTop: 0, paddingBottom: 0 }}
                component="nav"
                aria-label="main mailbox folders"
              >
                <StyledList>
                  <ListItemButton
                    sx={{
                      height: 32,
                      textAlign: "center",
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: "-7.5px",
                      borderBottom: "0.1px solid #A1A1A1",
                    }}
                    onClick={() => {
                      onUpdateRatesClick()
                      setOpenMenu((previousOpen) => !previousOpen)
                    }}
                  >
                    <ListItemText
                      primary="Editar"
                      primaryTypographyProps={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      height: 32,
                      textAlign: "center",
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: "-2px",
                      borderBottom: "0.1px solid #A1A1A1",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      onViewRatesClick()
                      setOpenMenu((previousOpen) => !previousOpen)
                    }}
                  >
                    <ListItemText
                      primary="Ver detalle"
                      primaryTypographyProps={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    />
                  </ListItemButton>
                </StyledList>
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      <ViewRateModal
        open={showUpdateRateModal}
        setOpen={setShowUpdateRateModal}
        rateId={selectedRate.id}
        rateBusiness={selectedRate.business}
        rateMovilnet={selectedRate.MovilnetRate}
        rateMovistar={selectedRate.MovistarRate}
        rateDigitel={selectedRate.DigitelRate}
        rateVigency={selectedRate.StartVigencyDate}
        rateMinimumBilling={selectedRate.MinimumBilling}
        update={updateModal}
        callback={modifyCallback}
      />
      <NewRateModal
        open={showNewRateModal}
        setOpen={setShowNewRateModal}
        callback={modifyCallback}
      />
    </React.Fragment>
  )
}

export default TableRatesData
